import { useEffect, useState } from "react";
import { staticPagesMap } from "../../constants/constants";
import axios from "axios";
import styles from "./StaticPages.module.css";
import { AppConfig } from "../../utils/config";
import { cleanHtmlString } from "../../utils/constants";

const staticId = staticPagesMap["Terms"];

export default function Terms() {
  const [staticText, setStaticText] = useState<string>("");

  useEffect(() => {
    axios
      .get(`${AppConfig.apiUrl}/static/${staticId}`)
      .then((res) => setStaticText(res.data.Content))
      .catch((err) => console.warn(err));
  }, []);

  return (
    <div>
      <div className={styles.editableBanner}>Terms & Conditions</div>
      <div
        dangerouslySetInnerHTML={{
          __html: cleanHtmlString(staticText).replace(/\\r\\n/g, "\n"),
        }}
      />
    </div>
  );
}
