import { useNavigate, useParams } from "react-router-dom";
import Items from "../../components/Items/Items";

export default function CustomerCategory() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate("/");
    return <>No Category Found</>;
  }

  return <Items isHome={false} category={parseInt(id)} />;
}
