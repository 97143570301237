import { Navigate, Outlet } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'

function PrivateRoutes() {
  const { userAuth } = useAppState()

  return userAuth?.email ? <Outlet /> : <Navigate to='/admin' replace />
}

export default PrivateRoutes
