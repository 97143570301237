import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./Discounts.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AppConfig } from "../../../utils/config";

type Discount = {
  ID: number;
  Code: string;
  Rate: string;
};

export default function Discounts() {
  const [discountCodes, setDiscountCodes] = useState<Discount[]>([]);
  const [newRate, setNewRate] = useState<string>("");
  const [newCode, setNewCode] = useState<string>("");
  const [discountDelete, setDiscountDelete] = useState<Discount | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const fetchDiscounts = () => {
    const token = localStorage.getItem("api-token");
    axios
      .get(`${AppConfig.apiUrl}/discounts`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => setDiscountCodes(res.data))
      .catch((err) => console.warn(err));
  };

  const createNewDiscount = () => {
    const token = localStorage.getItem("api-token");
    axios
      .post(
        `${AppConfig.apiUrl}/discounts`,
        {
          Code: newCode,
          Rate: newRate,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(() => {
        toast.success(`Created Discount: ${newCode}`);
        fetchDiscounts();
        setNewCode("");
        setNewRate("");
      })
      .catch((err) => console.warn(err));
  };

  const deleteDiscount = (id: number | undefined) => {
    if (id) {
      const token = localStorage.getItem("api-token");
      axios
        .delete(`${AppConfig.apiUrl}/discounts/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          toast.success(`Deleted Discount: ${discountDelete?.Code}`);
          setDiscountDelete(null);
          fetchDiscounts();
          setDeleteModal(false);
        })
        .catch((err) => console.warn(err));
    }
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  return (
    <div className={styles.discountContainer}>
      <h2>Discounts</h2>
      <h3>Current Discounts</h3>
      <TableContainer component={Paper} sx={{ width: "50%" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Discount Code
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Discount Rate (%)
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {discountCodes &&
              discountCodes.map((discount) => {
                return (
                  <TableRow key={discount.ID}>
                    <TableCell>{discount.Code}</TableCell>
                    <TableCell>{discount.Rate}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setDiscountDelete(discount);
                          setDeleteModal(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Set up new Discount Rate: </h3>
      <TableContainer component={Paper} sx={{ width: "50%" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Discount Code
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Discount Rate (%)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <OutlinedInput
                  size="small"
                  value={newCode}
                  onChange={(e) => setNewCode(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <OutlinedInput
                  size="small"
                  value={newRate}
                  onChange={(e) => setNewRate(e.target.value)}
                  endAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button fullWidth onClick={() => createNewDiscount()}>
          Save
        </Button>
      </TableContainer>
      <Dialog open={deleteModal} className={styles.deleteDiscountModal}>
        <DialogTitle style={{ fontWeight: "bold" }}>Are you sure?</DialogTitle>
        <DialogContent>
          <div>
            Confirm that you want to delete the following Discount Code.
          </div>
          <div>
            <p>Code: {discountDelete?.Code}</p>
            <p>Rate: {discountDelete?.Rate}</p>
          </div>
          <div>
            <Button
              variant="contained"
              color="error"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                setDeleteModal(false);
                setDiscountDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => deleteDiscount(discountDelete?.ID)}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
