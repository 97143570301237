import { Typography, Card, CardContent, Grid } from "@mui/material";
import { Order } from "../../../types/admin/orders";

export default function TransactionInfo({ order }: { order: Order }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          Transaction
        </Typography>
        <Grid item xs={4} sm={4}>
          <Typography variant="body1" component="span" color="textSecondary">
            Payment Method: {order.paymentMethod}
          </Typography>
        </Grid>
        {order.paymentMethod === "card" && (
          <>
            <Grid item xs={4} sm={4}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Transaction Status: {order.transaction.status}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Transaction Date:{" "}
                {new Date(
                  parseInt(order.transaction.trantime || "0") * 1000
                ).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Transaction Card Type: {order.transaction.cardtype}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Transaction Code: {order.transaction.txcode}
              </Typography>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}
