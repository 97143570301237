import { Order } from "../../types/admin/orders";
import { StyleSheet, Document, View, Text, Page } from "@react-pdf/renderer";
import { removeHtmlTagsAndEntities } from "../../utils/constants";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
  bold: {
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    paddingBottom: 5,
    marginBottom: 5,
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    padding: 5,
  },
  tableCell: {
    fontSize: 12,
    width: "33.33%",
    padding: 5,
  },
  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  customerDetails: {
    fontSize: 10,
  },
  text: {
    marginTop: 10,
  },
});

export default function PrintOrder({ data }: { data: Order }) {
  const totalPrice = data.items.reduce((total, item) => total + item.price, 0);
  const postage = data.postage;
  const grandTotal = totalPrice + postage; //parseFloat(postage) - parseFloat(discount);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Order Details</Text>
          <Text>Order placed: {data.orderDate}</Text>
          <Text>Order number: {data.orderNo}</Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <View>
              <Text style={styles.customerDetails}>Billing details:</Text>
              <Text style={styles.customerDetails}>
                {data.customer.title} {data.customer.name}{" "}
                {data.customer.lastName}
              </Text>
              <Text style={styles.customerDetails}>
                {data.customer.address1}
              </Text>
              <Text style={styles.customerDetails}>
                {data.customer.address2}
              </Text>
              <Text style={styles.customerDetails}>
                {data.customer.town}, {data.customer.county}
              </Text>
              <Text style={styles.customerDetails}>
                {data.customer.postcode}
              </Text>
              <Text style={styles.customerDetails}>
                {data.customer.country}
              </Text>
              <Text style={styles.customerDetails}>
                E-mail address: {data.customer.email}
              </Text>
            </View>
            <View>
              <Text style={styles.customerDetails}>Delivery details:</Text>
              <Text style={styles.customerDetails}>
                {data.title} {data.firstName} {data.lastName}
              </Text>
              <Text style={styles.customerDetails}>{data.address1}</Text>
              <Text style={styles.customerDetails}>{data.address2}</Text>
              <Text style={styles.customerDetails}>
                {data.town}, {data.county}
              </Text>
              <Text style={styles.customerDetails}>{data.postcode}</Text>
              <Text style={styles.customerDetails}>{data.country}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Items Ordered</Text>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={[styles.tableCell, styles.leftAlign]}>Quantity</Text>
            <Text style={[styles.tableCell, styles.leftAlign]}>Reference</Text>
            <Text style={styles.tableCell}>Description</Text>
            <Text style={[styles.tableCell, styles.rightAlign]}>Price</Text>
          </View>
          {data.items.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>1</Text>
              <Text style={styles.tableCell}>{item.reference}</Text>
              <Text style={styles.tableCell}>
                {removeHtmlTagsAndEntities(item.title)}
              </Text>
              <Text style={[styles.tableCell, styles.rightAlign]}>
                £{item.price}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.section}>
          <Text style={styles.bold}>
            Order subtotal: £{totalPrice.toFixed(2)}
          </Text>
          <Text style={styles.text}>Postage: £{postage.toFixed(2)}</Text>
          <Text style={[styles.bold, styles.text]}>
            Order total: £{grandTotal.toFixed(2)}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Additional Information</Text>
          {data.buyNotes && (
            <>
              <Text style={styles.bold}>Customer Buy Notes:</Text>
              <Text>{data.buyNotes}</Text>
            </>
          )}
          <Text style={[styles.bold, styles.text]}>
            Transaction Status: {data.transaction.status}
          </Text>
          <Text style={[styles.bold, styles.text]}>
            Payment Method: {data.transaction.cardtype}
          </Text>
          <Text style={[styles.bold, styles.text]}>
            Payment Received:{" "}
            {new Date(
              parseInt(data.transaction.trantime) * 1000
            ).toLocaleString()}
          </Text>
        </View>
      </Page>
    </Document>
  );
}
