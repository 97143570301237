import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./pages/App/App";
import { AppStateProvider } from "./hooks/useAppState";
import { BrowserRouter } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import { initializeApp } from "firebase/app";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

const firebaseConfig = {
  apiKey: "AIzaSyCGzB-FcPs-h2qYiDMItvzb68K6jagILaY",
  authDomain: "martin-townsend.firebaseapp.com",
  projectId: "martin-townsend",
  storageBucket: "martin-townsend.appspot.com",
  messagingSenderId: "459722016375",
  appId: "1:459722016375:web:a7fdefe13cf1bd46316872",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const signInWithEmail = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    toast.error("Incorrect username or password.");
  }
};

export const logout = () => {
  signOut(auth).then((r) => window.location.reload());
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#404761",
    },
  },
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <ToastContainer />
    <BrowserRouter>
      <AppStateProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AppStateProvider>
    </BrowserRouter>
  </>
);
