import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import styles from "./Customers.module.css";
import {
  DataGrid,
  GridCloseIcon,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { AppConfig } from "../../../utils/config";
import { Order } from "../../../types/admin/orders";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { fetchWithTokenCheck } from "../../../utils/utils";

type Customer = {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  country: string;
  id: number; // auto generated in database dont create or update
  orders: string;
  tempVisit: string;
  tempSpend: number;
  lastVisit: string;
  postArea: string;
  altAddress: string;
  comments: string;
  company: string;
  homePhone: string;
  otherPhone: string;
  pNames: string;
  password: string;
  registered: string;
};

type customerBatch = {
  email: string;
  id: number;
  lastOrder: string;
  name: string;
  totalSpend: number;
};

const initialCustomerState: Customer = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  address1: "",
  address2: "",
  town: "",
  county: "",
  postcode: "",
  country: "",
  id: 0, // auto generated in database dont create or update
  orders: "",
  tempVisit: "",
  tempSpend: 0,
  lastVisit: "",
  postArea: "",
  altAddress: "",
  comments: "",
  company: "",
  homePhone: "",
  otherPhone: "",
  pNames: "",
  password: "",
  registered: "",
};

export default function Customers() {
  const [searchOrderValue, setSearchOrderValue] = useState("by customer name");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<customerBatch[]>([]);
  const searchRef = useRef<HTMLInputElement>(null);

  // Modals
  const [addOpen, setAddOpen] = useState<boolean>(false);
  const [updateOpen, setUpdateOpen] = useState<boolean>(false);
  const [orderOpen, setOrderOpen] = useState<boolean>(false);

  // Customer
  const [customers, setCustomers] = useState<customerBatch[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);

  // Order
  const [orders, setOrders] = useState<Order[]>([]);
  const [orderPage, setOrderPage] = useState<number>(0);
  const [orderPageSize, setOrderPageSize] = useState<number>(100);
  const [orderTotalCount, setOrderTotalCount] = useState<number>(0);

  // Customer Value
  const [newCustomerValues, setNewCustomerValues] =
    useState<Customer>(initialCustomerState);
  const [updateCustomerValues, setUpdateCustomerValues] =
    useState<Customer>(initialCustomerState);

  function fetchCustomers() {
    setLoading(true);
    const auth = getAuth();

    const searchVal = searchRef.current?.value.toLowerCase();
    const searchFilter =
      searchOrderValue === "by customer name" ? "name" : "email";

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/customers?page=${page + 1}${
          searchVal && `&${searchFilter}=${searchVal}`
        }`,
      },
      auth
    ).then((res) => {
      setCustomers(res.data.customers);
      setTotalCount(res.data.totalCount);
      setLoading(false);
    });
  }

  function fetchSingleCustomer(custNo: number) {
    setLoading(true);
    const auth = getAuth();

    return fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/customer/${custNo}`,
      },
      auth
    )
      .then((res) => {
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  }

  function fetchCustomerOrders(custNo: number) {
    const auth = getAuth();

    return fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/orders?page=${
          orderPage + 1
        }&customer=${custNo}`,
      },
      auth
    )
      .then((res) => {
        setOrders(res.data.orders);
        setOrderTotalCount(res.data.totalCount);
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    fetchCustomerOrders(updateCustomerValues.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPage]);

  const handleSearchOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOrderValue((event.target as HTMLInputElement).value);
  };

  const handleSearch = () => {
    // will on search the current page the user is on will fix with search api
    const searchVal = searchRef.current?.value.toLowerCase();
    setSearchResults([]);
    if (searchVal) {
      const searchFilter =
        searchOrderValue === "by customer name" ? "name" : "email";
      setLoading(true);
      const auth = getAuth();

      fetchWithTokenCheck(
        {
          method: "GET",
          url: `${AppConfig.apiUrl}/customers?${searchFilter}=${searchVal}`,
        },
        auth
      )
        .then((res) => {
          setSearchResults(res.data.customers);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.warn(err);
        });
    } else {
      fetchCustomers();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (orderOpen) {
      fetchCustomerOrders(updateCustomerValues.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderOpen]);

  const handleAddMenu = () => {
    setAddOpen(!addOpen);
    setNewCustomerValues(initialCustomerState);
  };

  const handleOrderMenu = () => {
    setOrderOpen(!orderOpen);
  };

  const handleUpdateMenu = () => {
    setUpdateOpen(!updateOpen);
    setUpdateCustomerValues(initialCustomerState);
  };

  const handleAddCustomer = (event: React.FormEvent) => {
    event.preventDefault();

    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "POST",
        url: `${AppConfig.apiUrl}/customer`,
        data: {
          lastName: newCustomerValues.lastName,
          firstName: newCustomerValues.firstName,
          title: newCustomerValues.title,
          email: newCustomerValues.email,
          address1: newCustomerValues.address1,
          address2: newCustomerValues.address2,
          town: newCustomerValues.town,
          state: newCustomerValues.county,
          postalcode: newCustomerValues.postcode,
          country: newCustomerValues.country,
        },
      },
      auth
    )
      .then(() => {
        toast.success(`Created New Customer`);
        setAddOpen(!addOpen);
        fetchCustomers();
      })
      .catch((err) => console.warn(err));
  };

  const handleUpdateCustomer = (event: React.FormEvent) => {
    event.preventDefault();

    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "PUT",
        url: `${AppConfig.apiUrl}/customer/${updateCustomerValues.id}`,
        data: {
          firstName: updateCustomerValues.firstName,
          lastName: updateCustomerValues.lastName,
          title: updateCustomerValues.title,
          email: updateCustomerValues.email,
          customerNo: updateCustomerValues.id, // customer number
          address1: updateCustomerValues.address1,
          address2: updateCustomerValues.address2,
          town: updateCustomerValues.town,
          county: updateCustomerValues.county,
          postcode: updateCustomerValues.postcode,
          country: updateCustomerValues.country,
          orders: updateCustomerValues.orders,
          tempVisit: updateCustomerValues.tempVisit,
          tempSpend: updateCustomerValues.tempSpend,
          lastVisit: updateCustomerValues.lastVisit,
          postArea: updateCustomerValues.postArea,
          altAddress: updateCustomerValues.altAddress,
          comments: updateCustomerValues.comments,
          company: updateCustomerValues.company,
          homePhone: updateCustomerValues.homePhone,
          otherPhone: updateCustomerValues.otherPhone,
          pNames: updateCustomerValues.pNames,
          password: updateCustomerValues.password,
          registered: updateCustomerValues.registered,
        },
      },
      auth
    )
      .then(() => {
        toast.success(
          `Sucessfully Updated ${
            updateCustomerValues.firstName + " " + updateCustomerValues.lastName
          } Information`
        );
        setUpdateOpen(!updateOpen);
        fetchCustomers();
      })
      .catch((err) => console.warn(err));
  };
  const handleAddCustomerChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    const { name, value } = e.target;
    setNewCustomerValues({ ...newCustomerValues, [name]: value });
  };

  const handleUpdateCustomerChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    const { name, value } = e.target;
    setUpdateCustomerValues({ ...updateCustomerValues, [name]: value });
  };

  const handleRowClick = async (params: any) => {
    const singleCustomer = fetchSingleCustomer(params);
    setUpdateCustomerValues((await singleCustomer) || initialCustomerState);
    //Update to new fetch once everything else is organised
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Customer No",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Full Name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,
      width: 200,
    },

    {
      field: "email",
      headerName: "E-mail",
      width: 300,
      sortable: false,
      filterable: false,
    },
    {
      field: "lastOrder",
      headerName: "Last order",
      width: 100,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return dayjs(params.row.lastOrder).format("DD-MM-YYYY");
      },
    },
    {
      field: "totalSpend",
      headerName: "Total spend",
      width: 90,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return `£${Number(params.row.totalSpend).toFixed(2)}`;
      },
    },
  ];

  const orderColumns: GridColDef[] = [
    {
      field: "orderNo",
      headerName: "Order number",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "Full Name",
      headerName: "Customer Name",
      width: 200,
      sortable: false,
      filterable: false,
      valueGetter: (params) =>
        `${params.row.customer.title} ${params.row.customer.name} ${params.row.customer.lastName}`,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      description: "",
      sortable: false,
      filterable: false,
      width: 160,
    },
  ];

  const useEmailAsID = (row: customerBatch) => {
    return row.id;
  };

  const useOrderNoAsId = (row: Order) => {
    return row.orderNo;
  };

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const handleOrderPaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    setOrderPage(paginationModel.page);
    setOrderPageSize(paginationModel.pageSize);
  };

  return (
    <div className={styles.customerContainer}>
      <h2>Customer Management</h2>
      <div>
        <OutlinedInput
          style={{ marginRight: "1rem" }}
          size="small"
          name="Search for customer..."
          placeholder="Search for customer..."
          inputRef={searchRef}
          onKeyDown={handleKeyDown}
          type="text"
        />
        <FormControl>
          <RadioGroup
            row
            value={searchOrderValue}
            name="searchOrderValue"
            id="searchBox"
            onChange={handleSearchOrderChange}
          >
            <FormControlLabel
              value="by customer name"
              control={<Radio />}
              label="by customer name"
            />
            <FormControlLabel
              value="by email"
              control={<Radio />}
              label="by email"
            />
          </RadioGroup>
        </FormControl>
        <Button
          style={{ marginRight: "1rem" }}
          variant="contained"
          onClick={handleSearch}
        >
          Search
        </Button>

        <Button variant="contained" color="success" onClick={handleAddMenu}>
          Add new customer
        </Button>
      </div>
      {loading ? (
        <div className={styles.inlineLoader}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.customerTable}>
            {" "}
            <DataGrid
              getRowId={useEmailAsID}
              rows={
                searchResults && searchResults.length > 0
                  ? searchResults
                  : customers
              }
              columns={columns}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              loading={loading}
              rowCount={totalCount}
              paginationModel={{
                page: page,
                pageSize: pageSize,
              }}
              onRowClick={(params) => {
                handleRowClick(params.id);
                handleUpdateMenu();
              }}
              onPaginationModelChange={handlePaginationModelChange}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
            />
          </div>
        </>
      )}

      {/* New Customer Dialog */}
      <Dialog open={addOpen} onClose={handleAddMenu} fullWidth maxWidth="sm">
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddCustomer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="title"
                    name="title"
                    size="small"
                    placeholder="Title"
                    value={newCustomerValues.title}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="firstName"
                    name="firstName"
                    size="small"
                    placeholder="First Name"
                    value={newCustomerValues.firstName}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="lastName"
                    name="lastName"
                    size="small"
                    placeholder="Last Name"
                    value={newCustomerValues.lastName}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="email"
                    name="email"
                    size="small"
                    placeholder="Email"
                    value={newCustomerValues.email}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="address1"
                    name="address1"
                    size="small"
                    placeholder="Address 1"
                    value={newCustomerValues.address1}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="address2"
                    name="address2"
                    size="small"
                    placeholder="Address 2"
                    value={newCustomerValues.address2}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="town"
                    name="town"
                    size="small"
                    placeholder="Town"
                    value={newCustomerValues.town}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="county"
                    name="county"
                    size="small"
                    placeholder="County"
                    value={newCustomerValues.county}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="postcode"
                    name="postcode"
                    size="small"
                    placeholder="Postcode"
                    value={newCustomerValues.postcode}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="country"
                    name="country"
                    size="small"
                    placeholder="Country"
                    value={newCustomerValues.country}
                    onChange={handleAddCustomerChange}
                  />
                </FormControl>
              </Grid>
              <Grid alignSelf={"right"} item xs={12}>
                <div className={styles.addPostageSubmitButtons}>
                  <Button
                    style={{ marginRight: "1rem" }}
                    type="submit"
                    color="success"
                    variant="contained"
                  >
                    Add
                  </Button>
                  <Button
                    onClick={handleAddMenu}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {/* Update Customer Dialog */}
      <Dialog fullScreen open={updateOpen} onClose={handleUpdateMenu}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateMenu}
              aria-label="close"
            >
              <GridCloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {updateCustomerValues.firstName +
                " " +
                updateCustomerValues.lastName}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleUpdateCustomer}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form onSubmit={handleUpdateCustomer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="title"
                        name="title"
                        label="Title"
                        size="small"
                        placeholder="Title"
                        value={updateCustomerValues.title}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        size="small"
                        placeholder="First Name"
                        value={updateCustomerValues.firstName}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        size="small"
                        placeholder="Last Name"
                        value={updateCustomerValues.lastName}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        size="small"
                        placeholder="Email"
                        value={updateCustomerValues.email}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="address1"
                        name="address1"
                        size="small"
                        label="Address 1"
                        placeholder="Address 1"
                        value={updateCustomerValues.address1}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="address2"
                        name="address2"
                        size="small"
                        label="Address 2"
                        placeholder="Address 2"
                        value={updateCustomerValues.address2}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="town"
                        name="town"
                        label="Town"
                        size="small"
                        placeholder="Town"
                        value={updateCustomerValues.town}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="county"
                        name="county"
                        size="small"
                        label="County"
                        placeholder="County"
                        value={updateCustomerValues.county}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="postcode"
                        name="postcode"
                        label="Postcode"
                        size="small"
                        placeholder="Postcode"
                        value={updateCustomerValues.postcode}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="country"
                        name="country"
                        label="Country"
                        size="small"
                        placeholder="Country"
                        value={updateCustomerValues.country}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="company"
                        name="company"
                        label="Company"
                        size="small"
                        placeholder="Company"
                        value={updateCustomerValues.company}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="homePhone"
                        name="homePhone"
                        label="Home Phone"
                        size="small"
                        placeholder="Home Phone"
                        value={updateCustomerValues.homePhone}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="otherPhone"
                        name="otherPhone"
                        label="Other Phone"
                        size="small"
                        placeholder="Other Phone"
                        value={updateCustomerValues.otherPhone}
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="comments"
                        name="comments"
                        label="Comments 1"
                        value={updateCustomerValues.comments}
                        multiline
                        rows={6}
                        placeholder="Comments 1"
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="altAddress"
                        name="altAddress"
                        label="Alternate Address"
                        value={updateCustomerValues.altAddress}
                        multiline
                        rows={6}
                        placeholder="Comments"
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="pNames"
                        name="pNames"
                        label="Personal Names"
                        value={updateCustomerValues.pNames}
                        multiline
                        rows={6}
                        placeholder="Comments"
                        onChange={handleUpdateCustomerChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Button
                    style={{ marginRight: "1rem" }}
                    type="submit"
                    color="success"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginRight: "1rem" }}
                    onClick={handleUpdateMenu}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleOrderMenu}
                    color="primary"
                    variant="contained"
                  >
                    Order History
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* Customer Order Dialog */}
      <Dialog fullScreen open={orderOpen} onClose={handleOrderMenu}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOrderMenu}
              aria-label="close"
            >
              <GridCloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {updateCustomerValues.firstName +
                " " +
                updateCustomerValues.lastName}
            </Typography>
            <Typography variant="h6" component="div">
              Order History
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={styles.customerTable}>
            {" "}
            <DataGrid
              getRowId={useOrderNoAsId}
              rows={[...orders].sort((a, b) => b.orderNo - a.orderNo)}
              columns={orderColumns}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              loading={loading}
              rowCount={orderTotalCount}
              paginationModel={{
                page: orderPage,
                pageSize: orderPageSize,
              }}
              onPaginationModelChange={handleOrderPaginationModelChange}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <Button
              onClick={handleOrderMenu}
              style={{ marginRight: "1rem" }}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
            <Button color="success" variant="contained">
              Generate Label
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
