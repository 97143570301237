import {
  SetStateAction,
  Dispatch,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import styles from "./Static.module.css";
import axios from "axios";
import htmlToDraft from "html-to-draftjs";
import Loading from "../../../components/Loader/Loader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { AppConfig } from "../../../utils/config";
import {
  cleanHtmlString,
  editorColours,
  webSafeFonts,
} from "../../../utils/constants";
import emojis from "emojis-list";
import { getAuth } from "firebase/auth";
import { fetchWithTokenCheck } from "../../../utils/utils";

type StaticList = {
  ID: number;
  Title: string;
  Content: string;
};

export default function Static() {
  const [staticSites, setStaticSites] = useState<StaticList[]>([]);
  const [editorStates, setEditorStates] = useState<Record<number, EditorState>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStaticPages = () => {
    setLoading(true);
    axios
      .get(`${AppConfig.apiUrl}/static`)
      .then((res) => {
        const sites = res.data as StaticList[];
        setStaticSites(sites);
        const initialEditorStates = sites.reduce(
          (acc: Record<number, EditorState>, site: StaticList) => {
            const cleanContent = cleanHtmlString(
              site.Content.replace(/\\r\\n/g, "\n")
            ); // Replace \r\n with new line
            const contentBlock = htmlToDraft(cleanContent);
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            acc[site.ID] = EditorState.createWithContent(contentState);
            return acc;
          },
          {}
        );
        setLoading(false);
        setEditorStates(initialEditorStates);
      })
      .catch((err) => console.warn(err));
  };

  useEffect(() => {
    fetchStaticPages();
  }, []);

  const handleEditorStateChange = (id: number, editorState: EditorState) => {
    setEditorStates((prevStates) => ({
      ...prevStates,
      [id]: editorState,
    }));
  };

  const handleLineBreak = (
    id: number,
    editorState: EditorState,
    setEditorState: Dispatch<SetStateAction<Record<number, EditorState>>>
  ) => {
    return () => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "\u00A0", // Unicode for non-breaking space
        editorState.getCurrentInlineStyle()
      );

      setEditorState((prevStates: Record<number, EditorState>) => ({
        ...prevStates,
        [id]: EditorState.push(editorState, contentState, "insert-characters"),
      }));
    };
  };

  return (
    <div className={styles.staticContainer}>
      <h2>Edit Static Pages</h2>
      <div>
        {loading ? (
          <div className={styles.inlineLoader}>
            <Loading />
          </div>
        ) : (
          <>
            {staticSites.map((site) => {
              const editorState = editorStates[site.ID];
              return (
                <div key={site.ID}>
                  <h3>{site.Title}</h3>
                  <div className={styles.editorContainer}>
                    {editorState && (
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(newState) =>
                          handleEditorStateChange(site.ID, newState)
                        }
                        editorClassName={styles.editorContainer}
                        toolbar={{
                          fontFamily: {
                            options: webSafeFonts,
                          },
                          fontSize: {
                            default: 14,
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28,
                              30, 36, 48, 60, 72, 96,
                            ],
                          },
                          colorPicker: {
                            colors: editorColours,
                          },
                          emoji: {
                            emojis: emojis,
                          },
                        }}
                        toolbarCustomButtons={[
                          <div key="line-break">
                            <button
                              className="rdw-option-wrapper"
                              style={{ height: "80%" }}
                              onClick={handleLineBreak(
                                site.ID,
                                editorState,
                                setEditorStates
                              )}
                              type="button"
                            >
                              Space
                            </button>
                          </div>,
                        ]}
                      />
                    )}
                  </div>
                  <SaveButton
                    id={site.ID}
                    title={site.Title}
                    content={editorState}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

function SaveButton({
  id,
  title,
  content,
}: {
  id: number;
  title: string;
  content: EditorState;
}) {
  const auth = getAuth();

  const saveStaticPage = useCallback(() => {
    fetchWithTokenCheck(
      {
        method: "POST",
        url: `${AppConfig.apiUrl}/static`,
        data: {
          ID: id,
          Title: title,
          Content: draftToHtml(convertToRaw(content.getCurrentContent())),
        },
      },
      auth
    )
      .then(() => {
        toast.success(`Saved ${title} content.`);
      })
      .catch(() => {
        toast.error(`Failed to save ${title}.`);
      });
  }, [content, id, title, auth]);

  return (
    <Button variant="contained" onClick={saveStaticPage}>
      Save Changes
    </Button>
  );
}
