import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

export default function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div>Martin Townsend, P O Box 1100, Camberley, Surrey, GU15 9RY, UK</div>
      <div>
        Telephone: 07801 769 117 or 01462 420678 -
        Email:Martin@martintownsend.com
      </div>
      <br />
      <div>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/about'
        >
          About Us |
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/payment'
        >
          Payment |
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/delivery'
        >
          Delivery Information |
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/guarantee'
        >
          Guarantee & Returns |
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/valuation'
        >
          Valuation & Buying |
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/terms'
        >
          Terms & Conditions |
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            paddingRight: '5px',
          }}
          to='/contact'
        >
          Contact Us
        </Link>
      </div>
    </div>
  )
}
