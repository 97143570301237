import { Order } from "../../types/admin/orders";
import { StyleSheet, Document, View, Text, Page } from "@react-pdf/renderer";
import { removeHtmlTagsAndEntities } from "../../utils/constants";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  cell: {
    fontSize: 12,
  },
  tableHeader: {
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    paddingBottom: 5,
    marginBottom: 5,
  },
  tableCell: {
    fontSize: 12,
    width: "20%",
    padding: 2,
  },
  descriptionCell: {
    width: "60%",
  },
  rightAlign: {
    textAlign: "right",
  },
  bold: {
    fontWeight: "bold",
  },
  spacerCell: {
    fontSize: 12,
    width: "50%",
  },
  labelCell: {
    fontSize: 12,
    width: "12%",
  },
  valueCell: {
    fontSize: 12,
    width: "25%",
  },
});

export default function GenerateInvoice({ data }: { data: Order }) {
  // Convert the PHP logic to JavaScript
  const tmpname = `${data.customer.title} ${data.customer.name} ${data.customer.lastName}`;
  const ordnotxt = `Order no. ${data.orderNo}`;
  const ordatetxt = `Order date: ${data.orderDate}`;

  // Calculate total
  const total = data.items.reduce((sum, item) => sum + item.price, 0);
  const postage = data.postage;
  const grandTotal = total + postage;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.cell}>Deliver to:</Text>
          <Text style={styles.cell}>{tmpname}</Text>
          <Text style={styles.cell}>{data.address1}</Text>
          <Text style={styles.cell}>{data.address2}</Text>
          <Text style={styles.cell}>{data.town}</Text>
          <Text style={styles.cell}>{data.county}</Text>
          <Text style={styles.cell}>{data.postcode}</Text>
          <Text style={styles.cell}>{data.country}</Text>
        </View>

        <View style={{ ...styles.section, alignItems: "flex-end" }}>
          <Text style={styles.cell}>Martin Townsend</Text>
          <Text style={styles.cell}>P.O. Box 10</Text>
          <Text style={styles.cell}>Hitchin</Text>
          <Text style={styles.cell}>Hertfordhsire</Text>
          <Text style={styles.cell}>SG4 9PE</Text>
          <Text style={styles.cell}>United Kingdom</Text>
          <Text style={styles.cell}></Text>
          <Text style={styles.cell}>Tel. (+44) (0)1462 420678</Text>
          <Text style={styles.cell}>E-mail: martin@martintownsend.com</Text>
        </View>

        <View style={{ ...styles.section, alignItems: "center" }}>
          <Text style={styles.title}>INVOICE</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.cell}>{ordnotxt}</Text>
          <Text style={{ ...styles.cell, ...styles.rightAlign }}>
            {ordatetxt}
          </Text>
        </View>

        <View style={[styles.row, styles.tableHeader]}>
          <Text style={styles.tableCell}>Quantity</Text>
          <Text style={[styles.tableCell]}>Reference</Text>
          <Text style={[styles.tableCell, styles.descriptionCell]}>
            Description
          </Text>

          <Text style={[styles.tableCell, styles.rightAlign]}>Price</Text>
        </View>

        {data.items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>1</Text>
            <Text style={[styles.tableCell]}>{item.reference}</Text>
            <Text style={[styles.tableCell, styles.descriptionCell]}>
              {removeHtmlTagsAndEntities(item.title)}
            </Text>
            <Text style={[styles.tableCell, styles.rightAlign]}>
              £{item.price.toFixed(2)}
            </Text>
          </View>
        ))}

        <View style={{ ...styles.row, marginTop: 10 }}>
          <Text style={styles.spacerCell}></Text>
          <Text style={styles.labelCell}>Subtotal:</Text>
          <Text style={{ ...styles.valueCell, ...styles.rightAlign }}>
            £{total.toFixed(2)}
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.spacerCell}></Text>
          <Text style={styles.labelCell}>Postage:</Text>
          <Text style={{ ...styles.valueCell, ...styles.rightAlign }}>
            £{postage.toFixed(2)}
          </Text>
        </View>

        <View style={{ ...styles.row, marginTop: 10 }}>
          <Text style={styles.spacerCell}></Text>
          <Text
            style={{
              ...styles.labelCell,
              fontWeight: "bold",
            }}
          >
            TOTAL:
          </Text>
          <Text
            style={{
              ...styles.valueCell,
              ...styles.rightAlign,
              fontWeight: "bold",
            }}
          >
            £{grandTotal.toFixed(2)}
          </Text>
        </View>
      </Page>
    </Document>
  );
}
