import { Link } from "react-router-dom";
import { logout } from "../../index";
import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import Version from "../Version/Version";

export default function AdminNavigation() {
  function handleLogout() {
    logout();
  }

  return (
    <Paper style={{ height: "100vh" }}>
      <List>
        <ListItemButton onClick={() => handleLogout()}>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
        <Divider />
        <Version />
        <Divider />
        <Link
          to={"/admin/stock"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemText>Stock Management</ListItemText>
          </ListItemButton>
        </Link>
        <Link
          to={"/admin/orders"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemText>Order Management</ListItemText>
          </ListItemButton>
        </Link>
        <Link
          to={"/admin/customers"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemText>Customer Management</ListItemText>
          </ListItemButton>
        </Link>
        <Link
          to={"/admin/category"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemText>Category Management</ListItemText>
          </ListItemButton>
        </Link>
        <Link
          to={"/admin/postage"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemText>Postage Rates</ListItemText>
          </ListItemButton>
        </Link>
        {/* <Link
          to={'/admin/discounts'}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItemButton>
            <ListItemText>Discounts</ListItemText>
          </ListItemButton>
        </Link> */}
        {/* <Link
          to={'/admin/holiday'}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItemButton>
            <ListItemText>Holiday Settings</ListItemText>
          </ListItemButton>
        </Link> */}
        <Link
          to={"/admin/static"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemText>Static Pages</ListItemText>
          </ListItemButton>
        </Link>
        {/* <Link
          to={'/admin/accounts'}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItemButton>
            <ListItemText>User Account Management</ListItemText>
          </ListItemButton>
        </Link> */}
        {/* <Link
          to={'/admin/settings'}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItemButton>
            <ListItemText>Site Settings</ListItemText>
          </ListItemButton>
        </Link> */}
      </List>
    </Paper>
  );
}
