import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth, User } from "firebase/auth";

export const fetchWithTokenCheck = async <T = any>(
  axiosConfig: AxiosRequestConfig,
  auth: Auth,
  maxRetries = 1
): Promise<AxiosResponse<T>> => {
  try {
    // Get the current user from Firebase auth
    const user: User | null = auth.currentUser;

    if (!user) {
      throw new Error("No authenticated user found");
    }

    // Get a fresh token before making the request
    const token: string = await user.getIdToken(false);

    // Add the token to the request headers
    const configWithAuth: AxiosRequestConfig = {
      ...axiosConfig,
      headers: {
        ...axiosConfig.headers,
        Authorization: token,
      },
    };

    // Make the API request with the token
    return await axios<T>(configWithAuth);
  } catch (error: any) {
    // If token is expired (Firebase usually handles this automatically, but just in case)
    if (
      maxRetries > 0 &&
      (error.code === "auth/id-token-expired" || error.response?.status === 401)
    ) {
      // Force refresh the token
      const user: User | null = auth.currentUser;
      if (user) {
        await user.getIdToken(true); // Force refresh
        // Retry the request with one fewer retry attempt
        return fetchWithTokenCheck<T>(axiosConfig, auth, maxRetries - 1);
      }
    }

    // If we can't handle the error, rethrow it
    throw error;
  }
};
