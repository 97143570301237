import { useCallback, useEffect } from "react";

export function useScrollPosition(storageKey: string) {
  const saveScrollPosition = () => {
    sessionStorage.setItem(storageKey, window.scrollY.toString());
  };

  const restoreScrollPosition = useCallback(() => {
    const savedPosition = sessionStorage.getItem(storageKey);
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
    }
  }, [storageKey]);

  const clearScrollPosition = useCallback(() => {
    sessionStorage.removeItem(storageKey);
  }, [storageKey]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      clearScrollPosition();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [clearScrollPosition]);

  return {
    saveScrollPosition,
    restoreScrollPosition,
    clearScrollPosition,
  };
}
