import { useEffect } from "react";
import Items from "../../components/Items/Items";
import useAppState from "../../hooks/useAppState";

type Image = {
  Id: number;
  Reference: string;
};

export type Product = {
  ImageUrl: string;
  Title: string;
  Price: number;
  ID: number;
  Reference: string;
  Description: string;
  Images: Image[];
  Quantity: string;
  CleanTitle: {
    String: string;
    Valid: boolean;
  };
};

export default function Home() {
  const { setSelectedCategoryName } = useAppState();

  useEffect(() => {
    setSelectedCategoryName("");
  });

  return <Items isHome={true} />;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
  });
};
