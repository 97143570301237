import {
  PaymentElement,
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import styles from "./CheckoutForm.module.css";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  StripeAddressElementChangeEvent,
  StripeError,
} from "@stripe/stripe-js";
import axios from "axios";
import { Product } from "../../pages/Home/Home";
import { AppConfig } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import useAppState from "../../hooks/useAppState";

type Input = {
  [key: string]: string;
};

type PostageOptions = {
  id: number;
  label: string;
  rate: number;
};

export default function CheckoutForm({
  setShippingCost,
  order,
  totalCost,
  setError,
  error,
}: {
  setShippingCost: Dispatch<SetStateAction<number>>;
  order: Product[];
  totalCost: () => number;
  setError: Dispatch<SetStateAction<StripeError | null>>;
  error: StripeError | null;
}) {
  const navigate = useNavigate();
  const { setShoppingCart } = useAppState();
  const [inputs, setInputs] = useState<Input>({
    email: "",
    stitle: "",
    sfirstname: "",
    slastname: "",
    saddress1: "",
    saddress2: "",
    scountry: "",
    scounty: "",
    spostcode: "",
    stown: "",
  });
  const [note, setNote] = useState<string>("");
  const [shippingDetails, setShippingDetails] = useState<
    StripeAddressElementChangeEvent["value"] | null
  >(null);
  const [checked, setChecked] = useState(false);
  const [postageOptions, setPostageOptions] = useState<PostageOptions[] | null>(
    null
  );
  const [paymentMethod, setPaymentMethod] = useState<"card" | "cheque">("card");
  const [selectedPostageMethod, setSelectedPostageMethod] = useState<number>(0);

  useEffect(() => {
    if (!postageOptions) return;
    const cost = postageOptions.find((a) => a.id === selectedPostageMethod);
    setShippingCost(cost?.rate || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPostageMethod]);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cost = postageOptions
      ? postageOptions.find((a) => a.id === selectedPostageMethod)?.rate
      : 0;

    const postageMethod = postageOptions
      ? postageOptions.find((a) => a.id === selectedPostageMethod)?.label
      : "";

    localStorage.setItem(
      "order",
      JSON.stringify({
        purchasedStock: order,
        billingDetails: inputs,
        shippingDetails,
        buyersNotes: note,
        paymentMethod,
        postageRate: cost,
        postageType: postageMethod,
        shippingSameAsBilling: checked,
      })
    );

    await stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element.
        elements,
        confirmParams: {
          return_url: `https://martintownsend.com/success`,
          // return_url: `http://localhost:3000/success`,
        },
      })
      .catch((err: StripeError) => {
        if (err && paymentMethod === "cheque") {
          navigate("/success");
          setShoppingCart([]);
        } else {
          setError(err);
        }
      });
  };

  const onChange = (event: StripeAddressElementChangeEvent) => {
    setShippingDetails(event.value);
    if (event.complete) {
      const country = event.value.address.country;
      axios
        .post(`${AppConfig.apiUrl}/postageRates`, {
          country: country,
          orderTotal: totalCost(),
        })
        .then((res) => setPostageOptions(res.data))
        .catch((err) => console.warn(err));
    }
  };

  const formInComplete = useCallback(() => {
    // Destructure saddress2 out of the inputs object to exclude it
    const { saddress2, ...filteredInputs } = inputs;

    if (checked && selectedPostageMethod && inputs.email !== "") return false;

    // Check if any value in filteredInputs is an empty string
    return Object.values(filteredInputs).some((a) => a === "");
  }, [checked, inputs, selectedPostageMethod]);

  return (
    <div className={styles.topLevel}>
      <div className={styles.paymentSection}>
        <div>Select Payment Method</div>
        <Select value={paymentMethod} size="small" className={styles.select}>
          <MenuItem value={"card"} onClick={() => setPaymentMethod("card")}>
            Card
          </MenuItem>
          <MenuItem value={"cheque"} onClick={() => setPaymentMethod("cheque")}>
            Cheque
          </MenuItem>
        </Select>
      </div>
      <div className={styles.container}>
        <div className={styles.item}>
          <h4>Shipping Details</h4>
          <label className={styles.label}>
            E-Mail Address:
            <input
              className={styles.input}
              type="text"
              name="email"
              value={inputs.email || ""}
              onChange={handleChange}
            />
          </label>
          <label>
            Title
            <input
              className={styles.input}
              type="text"
              name="stitle"
              value={inputs.stitle || ""}
              onChange={handleChange}
            />
          </label>
          {paymentMethod === "card" && <PaymentElement />}
          <AddressElement
            onChange={onChange}
            options={{
              mode: "billing",
              display: {
                name: "split",
              },
            }}
          />
          {postageOptions && (
            <div className={styles.postageOptions}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Postage Options
                </InputLabel>
                <Select
                  value={selectedPostageMethod}
                  size="small"
                  label="Postage Options"
                >
                  {postageOptions &&
                    postageOptions.map((po) => {
                      return (
                        <MenuItem
                          key={po.id}
                          value={po.id}
                          onClick={() => setSelectedPostageMethod(po.id)}
                        >
                          {po.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          )}
          <label>
            Shipping Address Same as Billing Address?
            <input
              className={styles.inputCheck}
              type="checkbox"
              name="shippingsame"
              value={checked.toString()}
              onChange={handleCheckboxChange}
            />
          </label>
          <br />
          {paymentMethod === "cheque" && (
            <div style={{ fontSize: "12px" }}>
              <p>The total payable is shown above.</p>
              <p>
                Payments by sterling cheque should be made payable to Martin
                Townsend and MUST be drawn on a UK bank.
              </p>
              <p>Cheque payments should be posted to:</p>
              <p>P.O. Box 1100</p>
              <p>Camberley</p>
              <p>Surrey</p>
              <p>GU15 9RY</p>
              <p>UK</p>
              <p>
                Goods will be reserved for 5 working days to allow for cheque
                delivery. If the cheque doesn't arrive within this period the
                order will be deemed to have been cancelled. Customers based
                overseas who pay by UK cheque will be allowed some leeway.
              </p>
              <p>Your order will be sent on receipt of cleared funds.</p>
            </div>
          )}
          <br />
          <label>
            Message To Seller
            <textarea
              className={styles.input}
              name="note"
              value={note}
              onChange={handleNoteChange}
            />
          </label>
        </div>
        {!checked ? (
          <div className={styles.item}>
            <h4>Billing Details</h4>
            <label>
              First name
              <input
                className={styles.input}
                type="text"
                name="sfirstname"
                value={inputs.sfirstname || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Last name
              <input
                className={styles.input}
                type="text"
                name="slastname"
                value={inputs.slastname || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Country
              <input
                className={styles.input}
                type="text"
                name="scountry"
                value={inputs.scountry || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Address Line 1
              <input
                className={styles.input}
                type="text"
                name="saddress1"
                value={inputs.saddress1 || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Address Line 2
              <input
                className={styles.input}
                type="text"
                name="saddress2"
                value={inputs.saddress2 || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Town / City
              <input
                className={styles.input}
                type="text"
                name="stown"
                value={inputs.stown || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              County / State
              <input
                className={styles.input}
                type="text"
                name="scounty"
                value={inputs.scounty || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Postal Code
              <input
                className={styles.input}
                type="text"
                name="spostcode"
                value={inputs.spostcode || ""}
                onChange={handleChange}
              />
            </label>
          </div>
        ) : null}
      </div>
      <div className={styles.submitContainer}>
        <Button
          type="button"
          onClick={handleSubmit}
          className={styles.button}
          size="medium"
          variant="contained"
          disableElevation
          disabled={formInComplete()}
        >
          Submit - Total Cost £{totalCost()}
        </Button>
        {error !== null && (
          <div style={{ color: "red" }}>
            <h4>Payment Failed</h4>
            <p>Reason: {error.message}</p>
            <p>{error.decline_code}</p>
          </div>
        )}
      </div>
    </div>
  );
}
