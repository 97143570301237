import { Link } from "react-router-dom";
import HeaderLogo from "../../assets/header.jpeg";
import Navigation from "../Navigation/Navigation";
import styles from "./Header.module.css";
import useAppState from "../../hooks/useAppState";
import { useEffect, useState } from "react";
import { staticPagesMap } from "../../constants/constants";
import axios from "axios";
import { AppConfig } from "../../utils/config";
import { cleanHtmlString } from "../../utils/constants";
import { Button } from "@mui/material";

const staticId = staticPagesMap["Banner"];

export default function Header() {
  const { shoppingCart, selectedCategoryName } = useAppState();
  const [staticText, setStaticText] = useState<string>("");

  const findCartValue = () => {
    const value = shoppingCart.map((a) => a.Price);
    const sum = value.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sum.toFixed(2) || 0.0;
  };

  useEffect(() => {
    axios
      .get(`${AppConfig.apiUrl}/static/${staticId}`)
      .then((res) => setStaticText(res.data.Content))
      .catch((err) => console.warn(err));
  }, []);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <div className={styles.headerLogo}>
          <Link to="/">
            <img src={HeaderLogo} alt="header" />
          </Link>
        </div>
        <div className={styles.headerBasket}>
          <div>Your Basket</div>
          <div>{shoppingCart.length} items</div>
          <div>£{findCartValue()}</div>
          <Link to={"/cart"}>
            <Button
              variant="contained"
              size="small"
              disableElevation
              style={{ marginTop: "0.5rem" }}
            >
              View Basket
            </Button>
          </Link>
        </div>
      </div>
      <div className={styles.tagline}>
        Dealers in Fine Postage Stamps and Postal History - Valuations for
        Probate and Insurance
      </div>
      <Banner staticText={staticText} />
      <div>
        <Navigation admin={false} />
      </div>
      {selectedCategoryName !== "" ? (
        <div className={styles.editableBanner}>
          Items in Category: {selectedCategoryName}
        </div>
      ) : window.location.pathname === "/" ? (
        <div className={styles.editableBanner}>A Selection from our Stock</div>
      ) : null}
    </div>
  );
}

function Banner({ staticText }: { staticText: string }) {
  return (
    <div
      className={styles.editableBanner}
      dangerouslySetInnerHTML={{
        __html: cleanHtmlString(staticText).replace(/\\r\\n/g, "\n"),
      }}
    />
  );
}
