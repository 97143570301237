export function cleanHtmlString(htmlString: string) {
  // Remove incorrectly escaped quotes for &quot;
  htmlString = htmlString.replace(/\\&quot;/g, '"');

  // Fix improperly formatted style attributes with escaped quotes
  htmlString = htmlString.replace(
    /style="\\"([^"]*)\\""/g,
    function (match, p1) {
      return `style="${p1}"`;
    }
  );

  // Handle improperly formatted style attributes
  htmlString = htmlString.replace(
    /style="([^:;]+):\s*([^";]+?);?"/g,
    function (match, property, value) {
      // Ensure that valid styles with quotes and spaces are preserved
      if (property === "font-family" && value.includes("'")) {
        return `style="${property}: ${value.trim()};"`;
      }
      return `style="${property}: ${value.trim()};"`;
    }
  );

  // Remove any remaining incorrect escape sequences
  htmlString = htmlString.replace(/\\"/g, '"');

  return htmlString;
}

export const removeHtmlTagsAndEntities = (str: string) => {
  const regexTags = /(<([^>]+)>)/gi;
  const regexEntities = /&nbsp;/gi;
  return str.replace(regexTags, "").replace(regexEntities, " ");
};

export const webSafeFonts = [
  "American Typewriter",
  "Andale Mono",
  "Apple Chancery",
  "Arial",
  "Arial Black",
  "Arial Narrow",
  "Avant Garde",
  "Baskerville",
  "Book Antiqua",
  "Bradley Hand",
  "Brush Script MT",
  "Candara",
  "Century Gothic",
  "Cochin",
  "Comic Sans MS",
  "Copperplate",
  "Courier",
  "Courier New",
  "Didot",
  "Franklin Gothic Medium",
  "Garamond",
  "Geneva",
  "Gill Sans",
  "Helvetica",
  "Herculanum",
  "Impact",
  "Lucida Console",
  "Lucida Grande",
  "Lucida Sans",
  "Luminari",
  "Monaco",
  "Optima",
  "Palatino",
  "Papyrus",
  "Phosphate",
  "Snell Roundhand",
  "Symbol",
  "Tahoma",
  "Terminal",
  "Times New Roman",
  "Trattatello",
  "Trebuchet MS",
  "Verdana",
  "Webdings",
  "Zapfino",
];

export const editorColours = [
  // Red shades
  "rgb(255,204,204)", // light pink-red
  "rgb(255,153,153)", // soft pink-red
  "rgb(255,102,102)", // light red
  "rgb(255,51,51)", // bright red
  "rgb(204,0,0)", // medium red
  "rgb(153,0,0)", // dark red

  // Orange shades
  "rgb(255,229,204)", // very light orange
  "rgb(255,204,153)", // light peach
  "rgb(255,178,102)", // light orange
  "rgb(255,153,51)", // orange
  "rgb(255,128,0)", // bright orange
  "rgb(204,102,0)", // dark orange

  // Yellow shades
  "rgb(255,255,204)", // very light yellow
  "rgb(255,255,153)", // pale yellow
  "rgb(255,255,102)", // light yellow
  "rgb(255,255,51)", // bright yellow
  "rgb(255,204,0)", // golden yellow
  "rgb(204,153,0)", // dark yellow

  // Green shades
  "rgb(204,255,204)", // light mint green
  "rgb(153,255,153)", // pale green
  "rgb(102,255,102)", // light green
  "rgb(51,204,51)", // bright green
  "rgb(0,153,0)", // medium green
  "rgb(0,102,0)", // dark green

  // Blue shades
  "rgb(204,229,255)", // very light blue
  "rgb(153,204,255)", // soft blue
  "rgb(102,178,255)", // sky blue
  "rgb(51,153,255)", // bright blue
  "rgb(0,102,204)", // medium blue
  "rgb(0,51,153)", // dark blue

  // Purple shades
  "rgb(229,204,255)", // very light lavender
  "rgb(204,153,255)", // soft lavender
  "rgb(178,102,255)", // light purple
  "rgb(153,51,255)", // bright purple
  "rgb(102,0,204)", // medium purple
  "rgb(51,0,153)", // dark purple

  // Neutral (Gray and Brown) shades
  "rgb(240,240,240)", // very light gray
  "rgb(204,204,204)", // light gray
  "rgb(153,153,153)", // medium gray
  "rgb(102,102,102)", // dark gray
  "rgb(51,51,51)", // very dark gray
  "rgb(0,0,0)", // black

  "rgb(255,229,204)", // very light brown (tan)
  "rgb(255,204,153)", // light brown
  "rgb(204,153,102)", // medium brown
  "rgb(153,102,51)", // dark brown
  "rgb(102,51,0)", // chocolate brown
  "rgb(51,25,0)", // deep brown
];
