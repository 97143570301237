import { Button, Divider, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./Navigation.module.css";
import { useNavigate } from "react-router-dom";
import useAppState from "../../hooks/useAppState";
import axios from "axios";
import { AppConfig } from "../../utils/config";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import clsx from "clsx";

type RegionEntry = {
  ID: number;
  Name: string;
  Region: string;
  Reference: string;
  Xcoord: string;
  Ycoord: string;
  Parent: string;
  SortOrder: number;
};

type CategoriesList = {
  [key: string]: RegionEntry[];
};

export default function Navigation({
  admin = false,
  adminCallback,
  selectedCategories,
}: {
  admin?: boolean;
  adminCallback?: (categoryId: number, name: string) => void;
  selectedCategories?: number[];
}) {
  const navigate = useNavigate();
  const { setSelectedCategoryName, setSelectedPage } = useAppState();
  const { clearScrollPosition } = useScrollPosition("items-scroll-position");

  const [gbPostalHistoryMenu, setGbPostalHistoryMenu] =
    useState<null | HTMLElement>(null);
  const [gbStampsMenu, setGbStampsMenu] = useState<null | HTMLElement>(null);
  const [worldPostalMenu, setWorldPostalMenu] = useState<null | HTMLElement>(
    null
  );

  const [gbPostalHistoryCategories, setGbPostalHistoryCategories] =
    useState<CategoriesList>({});
  const [gbStampsCategories, setGbStampsCategories] = useState<CategoriesList>(
    {}
  );
  const [worldPostalCategories, setWorldPostalCategories] =
    useState<CategoriesList>({});

  const openGBPostal = Boolean(gbPostalHistoryMenu);
  const openGBStamps = Boolean(gbStampsMenu);
  const openWorldPostal = Boolean(worldPostalMenu);

  const handleClickGBPostal = (event: React.MouseEvent<HTMLElement>) => {
    setGbPostalHistoryMenu(event.currentTarget);
  };

  const handleClickGBStamps = (event: React.MouseEvent<HTMLElement>) => {
    setGbStampsMenu(event.currentTarget);
  };

  const handleClickWorldPostal = (event: React.MouseEvent<HTMLElement>) => {
    setWorldPostalMenu(event.currentTarget);
  };

  const handleClickHome = () => {
    setSelectedCategoryName("");
    clearScrollPosition();
    navigate("/");
  };

  const handleClose = () => {
    setGbPostalHistoryMenu(null);
    setGbStampsMenu(null);
    setWorldPostalMenu(null);
  };

  const handleCategoryClick = (categoryId: number, name: string) => {
    if (!admin) {
      setSelectedCategoryName(name);
      setSelectedPage(1);
      clearScrollPosition();
      handleClose();
      navigate(`/category/${categoryId}`);
    } else {
      adminCallback?.(categoryId, name);
    }
  };

  useEffect(() => {
    axios
      .get(`${AppConfig.apiUrl}/categories/1`)
      .then((res) => setGbPostalHistoryCategories(res.data))
      .catch((err) => console.warn(err));

    axios
      .get(`${AppConfig.apiUrl}/categories/2`)
      .then((res) => setGbStampsCategories(res.data))
      .catch((err) => console.warn(err));

    axios
      .get(`${AppConfig.apiUrl}/categories/3`)
      .then((res) => setWorldPostalCategories(res.data))
      .catch((err) => console.warn(err));
  }, []);

  const navigationButtons = (
    <>
      {!admin && (
        <Button
          style={{ background: "#404761" }}
          variant="contained"
          disableElevation
          onClick={handleClickHome}
        >
          Home
        </Button>
      )}
      <Button
        style={{ background: "#404761" }}
        variant="contained"
        disableElevation
        onClick={handleClickGBPostal}
      >
        GB Postal History
      </Button>
      <Button
        style={{ background: "#404761" }}
        variant="contained"
        disableElevation
        onClick={handleClickGBStamps}
      >
        GB Stamps
      </Button>
      <Button
        style={{ background: "#404761" }}
        variant="contained"
        disableElevation
        onClick={handleClickWorldPostal}
      >
        World Postal History
      </Button>
    </>
  );

  return (
    <>
      {/* Navigation */}
      <div
        className={clsx(
          !admin && styles.navigationMenu,
          admin && styles.adminMenu
        )}
      >
        {navigationButtons}
      </div>
      {/* Existing Menus */}
      <Menu
        anchorEl={gbPostalHistoryMenu}
        open={openGBPostal}
        onClose={handleClose}
        MenuListProps={{ sx: { py: 0 } }}
      >
        {Object.entries(gbPostalHistoryCategories).map(([region, cat]) => {
          if (region === "") return null;
          return (
            <div className={styles.regionGrid} key={region}>
              <Divider className={styles.divider} />
              <div className={styles.regionGridTitle}>{region}</div>
              <Divider className={styles.divider} />
              <div className={styles.regionGridItems}>
                {cat
                  .sort((a, b) => a.SortOrder - b.SortOrder)
                  .map((c) => {
                    return (
                      <MenuItem
                        key={c.Name}
                        onClick={() => handleCategoryClick(c.ID, c.Name)}
                        disableRipple
                        className={styles.regionGridItem}
                        sx={{
                          border: "1px solid white", // Add white border to menu items
                          fontSize: "14px", // Adjust the font size here
                          maxWidth: "150px",
                          whiteSpace: "normal", // Allow the text to wrap
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Add ellipsis to the overflow text
                          display: "flex", // Use flexbox for centering
                          alignItems: "center", // Center vertically
                          justifyContent: "center", // Center horizontally
                          textAlign: "center", // Center text horizontally
                          height: "40px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          lineHeight: "14px",
                          backgroundColor: selectedCategories?.includes(c.ID)
                            ? "#1976d2"
                            : "transparent",
                        }}
                      >
                        {c.Name}
                      </MenuItem>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </Menu>
      <Menu
        anchorEl={gbStampsMenu}
        open={openGBStamps}
        onClose={handleClose}
        MenuListProps={{ sx: { py: 0 } }}
      >
        {Object.entries(gbStampsCategories).map(([region, cat]) => {
          return (
            <div className={styles.navGrid} key={region}>
              <div className={styles.navGridItems}>
                {cat
                  .sort((a, b) => a.SortOrder - b.SortOrder)
                  .map((c) => {
                    return (
                      <MenuItem
                        className={styles.navGridItem}
                        key={c.Name}
                        onClick={() => handleCategoryClick(c.ID, c.Name)}
                        disableRipple
                        sx={{
                          border: "1px solid white", // Add white border to menu items
                          fontSize: "14px", // Adjust the font size here
                          maxWidth: "150px",
                          whiteSpace: "normal", // Allow the text to wrap
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Add ellipsis to the overflow text
                          display: "flex", // Use flexbox for centering
                          alignItems: "center", // Center vertically
                          justifyContent: "center", // Center horizontally
                          textAlign: "center", // Center text horizontally
                          minHeight: "40px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          lineHeight: "14px",
                          backgroundColor: selectedCategories?.includes(c.ID)
                            ? "#1976d2"
                            : "transparent",
                        }}
                      >
                        {c.Name}
                      </MenuItem>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </Menu>
      <Menu
        anchorEl={worldPostalMenu}
        open={openWorldPostal}
        onClose={handleClose}
        MenuListProps={{ sx: { py: 0 } }}
      >
        {Object.entries(worldPostalCategories).map(([region, cat]) => {
          return (
            <div className={styles.navGrid} key={region}>
              <div className={styles.navGridItems}>
                {cat
                  .sort((a, b) => a.SortOrder - b.SortOrder)
                  .map((c) => {
                    return (
                      <MenuItem
                        className={styles.navGridItem}
                        key={c.Name}
                        onClick={() => handleCategoryClick(c.ID, c.Name)}
                        disableRipple
                        sx={{
                          border: "1px solid white", // Add white border to menu items
                          fontSize: "14px", // Adjust the font size here
                          maxWidth: "150px",
                          whiteSpace: "normal", // Allow the text to wrap
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Add ellipsis to the overflow text
                          display: "flex", // Use flexbox for centering
                          alignItems: "center", // Center vertically
                          justifyContent: "center", // Center horizontally
                          textAlign: "center", // Center text horizontally
                          height: "40px",
                          fontFamily: "serif",
                          fontWeight: "bold",
                          lineHeight: "14px",
                          backgroundColor: selectedCategories?.includes(c.ID)
                            ? "#1976d2"
                            : "transparent",
                        }}
                      >
                        {c.Name}
                      </MenuItem>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </Menu>
    </>
  );
}
