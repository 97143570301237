import { useState } from "react";
import ImageGallery from "react-image-gallery";
import { GalleryImage } from "../../types/images";
import styles from "./Gallery.module.css";
import clsx from "clsx";

export default function Gallery({
  images,
  galleryRef,
}: {
  images: GalleryImage[];
  galleryRef: React.RefObject<ImageGallery>;
}) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = (fullScreen: boolean) => {
    setIsFullscreen(fullScreen);
  };

  return (
    <ImageGallery
      items={images}
      ref={galleryRef}
      showPlayButton={false}
      useBrowserFullscreen={false}
      onScreenChange={handleFullscreen}
      renderItem={(item) => {
        return (
          <img
            className={clsx(isFullscreen ? styles.fullscreen : styles.normal)}
            src={item.original}
            alt={item.originalAlt}
          />
        );
      }}
    />
  );
}
