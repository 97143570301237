import { useSearchParams } from "react-router-dom";
import { AppConfig } from "../../utils/config";
import { Product } from "../Home/Home";
import styles from "./OrderSuccess.module.css";
import { useEffect } from "react";
import axios from "axios";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";

type Order = {
  purchasedStock: Product[];
  buyersNotes: string;
  paymentMethod: string;
  postageType: string;
  postageRate: number;
  billingDetails: Customer;
  shippingDetails: StripeAddressElementChangeEvent["value"];
  shippingSameAsBilling: boolean;
};

type Customer = {
  email: string;
  saddress1: string;
  saddress2: string;
  sfirstname: string;
  slastname: string;
  stitle: string;
  scountry: string;
  scounty: string;
  spostcode: string;
  stown: string;
};

function getOrderFromLocalStorage(): Order {
  const order = localStorage.getItem("order");
  return order ? JSON.parse(order) : [];
}

export default function OrderSuccess() {
  const order = getOrderFromLocalStorage();
  const [searchParams] = useSearchParams();

  const paymentIntent = searchParams.get("payment_intent");

  useEffect(() => {
    if (order && order.purchasedStock) {
      const items = order.purchasedStock.map((a) => a.ID);
      if (order) {
        const billingDetails = order.shippingSameAsBilling
          ? {
              firstName: order.shippingDetails.firstName,
              lastName: order.shippingDetails.lastName,
              title: order.billingDetails.stitle,
              address1: order.shippingDetails.address.line1,
              address2: order.shippingDetails.address.line2,
              country: order.shippingDetails.address.country,
              county: order.shippingDetails.address.state,
              postCode: order.shippingDetails.address.postal_code,
              town: order.shippingDetails.address.city,
            }
          : {
              firstName: order.billingDetails.sfirstname,
              lastName: order.billingDetails.slastname,
              title: order.billingDetails.stitle,
              address1: order.billingDetails.saddress1,
              address2: order.billingDetails.saddress2,
              country: order.billingDetails.scountry,
              county: order.billingDetails.scounty,
              postCode: order.billingDetails.spostcode,
              town: order.billingDetails.stown,
            };

        axios
          .post(`${AppConfig.apiUrl}/submitOrder`, {
            paymentIntent: order.paymentMethod === "card" ? paymentIntent : "",
            shippingSameAsBilling: order.shippingSameAsBilling,
            ...billingDetails,
            email: order.billingDetails.email,
            deliveryFirstName: order.shippingDetails.firstName,
            deliveryLastName: order.shippingDetails.lastName,
            deliveryTitle: order.billingDetails.stitle,
            deliveryAddress1: order.shippingDetails.address.line1,
            deliveryAddress2: order.shippingDetails.address.line2,
            deliveryCountry: order.shippingDetails.address.country,
            deliveryCounty: order.shippingDetails.address.state,
            deliveryPostCode: order.shippingDetails.address.postal_code,
            deliveryTown: order.shippingDetails.address.city,
            buyNotes: order.buyersNotes,
            purchasedStock: items,
            paymentMethod: order.paymentMethod,
            postageRate: order.postageRate,
            postageType: order.postageType,
          })
          .then(() => {
            localStorage.removeItem("order");
          })
          .catch(() => {
            localStorage.removeItem("order");
          });
      }
    }
  }, [order, paymentIntent]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Order Successful!</h1>
      <p className={styles.subtitle}>
        Thank you for your purchase. Your order has been successfully placed.
      </p>
      <div className={styles.productsContainer}>
        {order && order.purchasedStock
          ? order.purchasedStock.map((product: Product) => (
              <div key={product.ID} className={styles.productCard}>
                <img
                  src={`${AppConfig.smImgUrl}/${product.ID}.jpg`}
                  alt={product.Title}
                  className={styles.productImage}
                />
                <div className={styles.productDetails}>
                  <h4
                    className={styles.productTitle}
                    dangerouslySetInnerHTML={{
                      __html: product.Title.replace(/\\r\\n/g, "\n"),
                    }}
                  />
                  <p className={styles.productPrice}>
                    £{product.Price.toFixed(2)}
                  </p>
                  <p className={styles.productReference}>
                    Reference: {product.Reference}
                  </p>
                </div>
              </div>
            ))
          : null}
      </div>
      <div className={styles.footer}>
        <p className={styles.footerText}>
          If you have any questions, please contact our support team.
        </p>
      </div>
    </div>
  );
}
