import styles from "./Postage.module.css";
import { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/Loader/Loader";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { AppConfig } from "../../../utils/config";
import { getAuth } from "firebase/auth";
import { fetchWithTokenCheck } from "../../../utils/utils";

export type PostageItem = {
  id: any;
  ID: number;
  Region: string;
  Type: string;
  Rate: number;
};

export default function Postage() {
  const [postageList, setPostageList] = useState<PostageItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [postageDelete, setPostageDelete] = useState<PostageItem | null>(null);
  const [addOpen, setAddOpen] = useState(false);
  const [updatedValues, setUpdatedValues] = useState<{
    [key: number]: PostageItem;
  }>({});
  const [postageFormValues, setPostageFormValues] = useState({
    region: "",
    service: "",
    rate: "",
  });
  const fetchPostage = () => {
    setLoading(true);
    axios
      .get(`${AppConfig.apiUrl}/postage`)
      .then((res) => {
        setLoading(false);
        setPostageList(res.data);
      })
      .catch((err) => console.warn(err));
  };

  useEffect(() => {
    fetchPostage();
  }, []);

  // Handles deleting of postage rates when button clicked
  const handleDelete = () => {
    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "DELETE",
        url: `${AppConfig.apiUrl}/postage/${postageDelete?.ID}`,
      },
      auth
    )
      .then(() => {
        toast.success(`Deleted ${postageDelete?.Region} postage rate.`);
        setDeleteOpen(!deleteOpen);
        fetchPostage();
        setPostageDelete(null);
      })
      .catch((err) => console.warn(err));
  };

  const handleAdd = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const auth = getAuth();
    const postageRate = postageFormValues.rate;

    fetchWithTokenCheck(
      {
        method: "POST",
        url: `${AppConfig.apiUrl}/postage`,
        data: {
          type: postageFormValues.service,
          region: postageFormValues.region,
          rate: parseFloat(postageRate),
        },
      },
      auth
    )
      .then(() => {
        toast.success(`Created Postage Rate:${postageFormValues.service}`);
        setAddOpen(!addOpen);
        fetchPostage();
      })
      .catch((err) => console.warn(err));
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setPostageFormValues({ ...postageFormValues, [name]: value });
  };

  const handleDeleteMenu = (item: PostageItem | null) => {
    setDeleteOpen(!deleteOpen);
    if (item !== null) {
      setPostageDelete(item);
    }
  };
  const handleAddMenu = () => {
    setAddOpen(!addOpen);
  };

  const handleRateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
    valueType: string
  ) => {
    const newValue = e.target.value;

    setUpdatedValues((prev) => ({
      ...prev,
      [id]: { ...prev[parseFloat(id)], [valueType]: newValue },
    }));
  };

  const submitRateChange = () => {
    const auth = getAuth();

    Object.keys(updatedValues).map(async (key) => {
      const objKey = parseInt(key);

      try {
        await fetchWithTokenCheck(
          {
            method: "PUT",
            url: `${AppConfig.apiUrl}/postage/${key}`,
            data: {
              type:
                updatedValues[objKey].Type ??
                postageList.find((a) => a.ID === objKey)?.Type,
              region: postageList.find((a) => a.ID === objKey)?.Region,
              rate:
                parseFloat(updatedValues[objKey].Rate?.toString()) ||
                postageList.find((a) => a.ID === objKey)?.Rate,
            },
          },
          auth
        );
        toast.success(
          `Sucessfully Updated:${
            postageList.find((a_1) => a_1.ID === objKey)?.Region
          }`
        );
        fetchPostage();
      } catch (err) {
        return console.warn(err);
      }
    });

    setUpdatedValues({});
  };

  return (
    <div className={styles.postageContainer}>
      <h2>Postage Rates</h2>
      <p>Your current postage rates are below:</p>
      {loading ? (
        <div className={styles.inlineLoader}>
          <Loading />
        </div>
      ) : (
        <>
          <table className={styles.postageTable}>
            <thead>
              <tr>
                <th className={styles.region}>Region</th>
                <th className={styles.service}>Service</th>
                <th className={styles.rates}>Postage Rate</th>
              </tr>
            </thead>
            <tbody>
              {postageList.map((item) => {
                return (
                  <tr key={item.ID}>
                    <td>{item.Region}</td>
                    <td>
                      <OutlinedInput
                        size="small"
                        fullWidth
                        value={updatedValues[item.ID]?.Type ?? item.Type}
                        onChange={(e) => {
                          handleRateChange(e, item.ID.toString(), "Type");
                        }}
                      />
                    </td>
                    <td>
                      <OutlinedInput
                        size="small"
                        type="text"
                        startAdornment={
                          <InputAdornment position="start">£</InputAdornment>
                        }
                        value={updatedValues[item.ID]?.Rate ?? item.Rate}
                        onChange={(e) => {
                          handleRateChange(e, item.ID.toString(), "Rate");
                        }}
                      />
                    </td>
                    <td>
                      <IconButton
                        onClick={() => handleDeleteMenu(item)}
                        aria-label="delete"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <OutlinedInput
            size="small"
            placeholder="Note to customers..."
            fullWidth
          />
          <div className={styles.btnGroup}>
            <Button
              className={styles.updateBtn}
              variant="contained"
              onClick={submitRateChange}
            >
              Update
            </Button>
            <Button
              onClick={handleAddMenu}
              className={styles.updateBtn}
              variant="contained"
              color="success"
            >
              Add +
            </Button>
          </div>
        </>
      )}

      <Dialog
        open={deleteOpen}
        onClose={() => handleDeleteMenu(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this postage item?{" "}
            {postageDelete?.Region} {postageDelete?.Rate}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteMenu(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addOpen}
        onClose={handleAddMenu}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add new postage rate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter the details of the new postage rate.
          </DialogContentText>

          <form onSubmit={handleAdd}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <OutlinedInput
                id="region"
                name="region"
                size="small"
                value={postageFormValues.region}
                placeholder="Region"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth margin="normal" required>
              <OutlinedInput
                size="small"
                placeholder="Service"
                name="service"
                value={postageFormValues.service}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth margin="normal" required>
              <OutlinedInput
                size="small"
                type="number"
                name="rate"
                value={postageFormValues.rate}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">£</InputAdornment>
                }
              />
            </FormControl>
            <div className={styles.addPostageSubmitButtons}>
              <Button
                style={{ marginRight: "1rem" }}
                type="submit"
                color="success"
                variant="contained"
              >
                Add
              </Button>
              <Button
                onClick={handleAddMenu}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
