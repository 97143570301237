import { Button, OutlinedInput } from '@mui/material'

export default function Holiday() {
  return (
    <div>
      <h2>Holiday Settings</h2>
      <div>
        <p>
          Holiday mode is currently turned off. Fill in the details below and
          click the 'Turn on' button to activate.
        </p>
        <OutlinedInput size='small' placeholder='Date of return...' fullWidth />
        <OutlinedInput size='small' placeholder='Custom message' fullWidth />
        <Button variant='contained'>Turn On</Button>
      </div>
    </div>
  )
}
