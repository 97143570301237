import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import styles from "./Stock.module.css";
import {
  DataGrid,
  GridCloseIcon,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Loading from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { ContentState, convertToRaw, EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { RegionEntry } from "../Category/Category";
import draftToHtml from "draftjs-to-html";
import { AppConfig } from "../../../utils/config";
import {
  cleanHtmlString,
  editorColours,
  webSafeFonts,
} from "../../../utils/constants";
import dayjs from "dayjs";
import { toggleCustomInlineStyle } from "draftjs-utils";
import "draft-js/dist/Draft.css";
import { ColorResult, SketchPicker } from "react-color";
import { Map } from "immutable";
import rgbHex from "rgb-hex";
import Draggable from "react-draggable";
import Navigation from "../../../components/Navigation/Navigation";
import { fetchWithTokenCheck } from "../../../utils/utils";
import { getAuth } from "firebase/auth";

type Image = {
  Id: number;
  Reference: string;
};

type StockT = {
  ID: number;
  Reference: number;
  Title: string;
  SubCategory: string;
  BoughtFrom: string;
  BoughtPrice: string;
  BoughtDate: string;
  Price: number;
  SoldTo: string;
  SoldDate: string;
  SellingPrice: string;
  InventoryNumber: string;
  Description: string;
  ItemState: string;
  Quantity: string;
  AddDate: string;
  Area: string;
  Images?: Image[];
  IsSheet: boolean;
};

type NewStockT = Omit<StockT, "ID">;

const initialStockState: StockT = {
  ID: 0,
  Reference: 0,
  Title: "",
  SubCategory: "",
  BoughtFrom: "",
  BoughtPrice: "",
  BoughtDate: "",
  Price: 0,
  SoldTo: "",
  SoldDate: "",
  SellingPrice: "",
  InventoryNumber: "",
  Description: "",
  ItemState: "",
  Quantity: "",
  AddDate: "",
  Area: "",
  IsSheet: false,
};

const newInitialStockState: NewStockT = {
  Reference: 0,
  Title: "",
  SubCategory: "",
  BoughtFrom: "",
  BoughtPrice: "",
  BoughtDate: "",
  Price: 0,
  SoldTo: "",
  SoldDate: "",
  SellingPrice: "",
  InventoryNumber: "",
  Description: "",
  ItemState: "",
  Quantity: "",
  AddDate: "",
  Area: "",
  IsSheet: false,
};

type CategoriesList = {
  [key: string]: ExtendedRegionEntry[];
};

type ExtendedRegionEntry = RegionEntry & {
  categoryKey: string;
};

const parseHTMLString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};

const getMatchingCategories = (
  categoryString: string,
  allCategories: ExtendedRegionEntry[]
): ExtendedRegionEntry[] => {
  const categoryIDs = categoryString
    .split("|")
    .map((id) => parseInt(id.trim(), 10));
  return allCategories.filter((category) => categoryIDs.includes(category.ID));
};

const checkQuantity = (quantity: string) => {
  if (quantity.includes("0") || quantity.includes("1")) return quantity;
  else return "0";
};

const lineHeightOptions = [1.0, 1.15, 1.5, 2.0, 2.5, 3.0];

const LineHeightBlock = ({ blockProps, children }: any) => {
  const { style } = blockProps;
  return <div style={style}>{children}</div>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const myBlockStyleFn = (contentBlock: any) => {
  const lineHeight = contentBlock.getData().get("lineHeight");
  if (lineHeight) {
    return {
      component: LineHeightBlock,
      editable: true,
      props: {
        style: { lineHeight },
      },
    };
  }
  return undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LineHeightOption = ({ editorState, setEditorState }: any) => {
  const setLineHeight = (lineHeight: string) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const newContentState = Modifier.setBlockData(
      contentState,
      selection,
      Map().set("line-height", parseFloat(lineHeight))
    );

    setEditorState(
      EditorState.push(editorState, newContentState, "change-block-data")
    );
  };

  return (
    <select
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onChange={(e) => setLineHeight(e.target.value)}
      defaultValue="1.0"
      style={{
        padding: "5px",
        border: "1px solid #F1F1F1",
        fontSize: "12px",
        height: "30px",
        cursor: "pointer",
      }}
    >
      {lineHeightOptions.map((lh) => (
        <option key={lh} value={lh}>
          {lh}x
        </option>
      ))}
    </select>
  );
};

// Create a custom toolbar component
const CustomToolbar = () => {
  return {
    fontFamily: {
      options: webSafeFonts,
      className: "rdw-fontfamily-wrapper",
      component: (props: any) => {
        const { expanded, onExpandEvent, onChange, currentState } = props;

        return (
          <>
            <div
              className="rdw-fontfamily-wrapper"
              aria-label="rdw-font-family-control"
            >
              <div
                className="rdw-dropdown-wrapper"
                aria-expanded={expanded}
                onClick={onExpandEvent}
              >
                <div className="rdw-dropdown-selectedtext">
                  {currentState.fontFamily || "Font"}
                </div>
                {expanded && (
                  <Draggable handle=".font-family-handle" bounds="body">
                    <div
                      className="rdw-dropdown-optionwrapper"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="font-family-handle"
                        style={{
                          padding: "8px",
                          background: "#f0f0f0",
                          cursor: "move",
                          borderRadius: "4px 4px 0 0",
                          textAlign: "center",
                        }}
                      >
                        Drag to move
                      </div>
                      {webSafeFonts.map((font) => (
                        <div
                          key={font}
                          className="rdw-dropdown-option"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChange(font);
                          }}
                        >
                          {font}
                        </div>
                      ))}
                    </div>
                  </Draggable>
                )}
              </div>
            </div>
          </>
        );
      },
    },
  };
};

export default function Stock() {
  const [searchOrderValue, setSearchOrderValue] = useState(
    "by reference number"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [latestReference, setLatestReference] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<StockT[]>([]);
  const searchRef = useRef<HTMLInputElement>(null);
  const [stock, setStock] = useState<StockT[]>([]);
  const [addOpen, setAddOpen] = useState<boolean>(false);
  const [updateOpen, setUpdateOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [newStockValues, setNewStockValues] =
    useState<NewStockT>(newInitialStockState);
  const [updateStockValues, setUpdateStockValues] =
    useState<StockT>(initialStockState);
  const [titleEditorState, setTitleEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [descEditorState, setDescEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [showTitleColorPicker, setShowTitleColorPicker] = useState(false);
  const [showDescColorPicker, setShowDescColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState("#000000");
  const [categories, setCategories] = useState<{
    gbPostalHistory: CategoriesList;
    gbStamps: CategoriesList;
    worldPostal: CategoriesList;
  }>({
    gbPostalHistory: {},
    gbStamps: {},
    worldPostal: {},
  });
  const [gbph, setGBPH] = useState<ExtendedRegionEntry[]>([]);
  const [gs, setGS] = useState<ExtendedRegionEntry[]>([]);
  const [wp, setWP] = useState<ExtendedRegionEntry[]>([]);
  const [frontPageItem, setFrontPageItem] = useState<boolean>(false);
  const [isSheet, setIsSheet] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [extraImages, setExtraImages] = useState<File[]>([]);
  const [selectedExtraImages, setSelectedExtraImages] = useState<Image[]>([]);

  const flattenCategories = (
    categoriesList: CategoriesList
  ): ExtendedRegionEntry[] => {
    const flatCategories: ExtendedRegionEntry[] = [];

    const flatten = (
      item: ExtendedRegionEntry | ExtendedRegionEntry[],
      key: string
    ) => {
      if (Array.isArray(item)) {
        item
          .sort((a, b) => a.SortOrder - b.SortOrder)
          .forEach((subItem) => flatten(subItem, key));
      } else {
        flatCategories.push({ ...item, categoryKey: key });
      }
    };

    Object.entries(categoriesList).forEach(([key, item]) => flatten(item, key));

    return flatCategories;
  };

  function fetchStock() {
    setLoading(true);
    const auth = getAuth();
    const searchVal = searchRef.current?.value.toLowerCase();
    const searchFilter =
      searchOrderValue === "by reference number" ? "reference" : "title";
    setSearchResults([]);

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/stock?page=${page + 1}${
          searchVal && `&${searchFilter}=${searchVal}`
        }`,
      },
      auth
    )
      .then((res) => {
        setStock(res.data.stock);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((err) => console.warn(err));
  }

  function fetchReference() {
    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/reference`,
      },
      auth
    ).then((res) => {
      setLatestReference(res.data);
      setNewStockValues((prev) => {
        return {
          ...prev,
          Reference: res.data + 1,
        };
      });
    });
  }

  function fetchSingleStock(stockNo: any) {
    setLoading(true);
    const auth = getAuth();

    return fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/stock/${stockNo}`,
      },
      auth
    )
      .then((res) => {
        setLoading(false);
        fetchCategories();
        setSelectedImage(`${AppConfig.lgImgUrl}/${res.data.ID}.jpg`);

        if (res.data.Images && res.data.Images.length > 0) {
          const images = res.data.Images;
          setSelectedExtraImages(images);
        }

        return res.data;
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  }

  function fetchCategories() {
    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/categories/1`,
      },
      auth
    )
      .then((res) =>
        setCategories((prev) => ({ ...prev, gbPostalHistory: res.data }))
      )
      .catch((err) => console.warn(err));

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/categories/2`,
      },
      auth
    )
      .then((res) => setCategories((prev) => ({ ...prev, gbStamps: res.data })))
      .catch((err) => console.warn(err));

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/categories/3`,
      },
      auth
    )
      .then((res) =>
        setCategories((prev) => ({ ...prev, worldPostal: res.data }))
      )
      .catch((err) => console.warn(err));
  }

  useEffect(() => {
    fetchStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (addOpen) {
      fetchCategories();
      fetchReference();
      setFrontPageItem(false);
      setIsSheet(false);
      setTitleEditorState(EditorState.createEmpty());
      setDescEditorState(EditorState.createEmpty());
    }
  }, [addOpen]);

  useEffect(() => {
    const gbph = getMatchingCategories(
      updateStockValues.SubCategory,
      flattenCategories(categories.gbPostalHistory)
    );
    const gs = getMatchingCategories(
      updateStockValues.SubCategory,
      flattenCategories(categories.gbStamps)
    );
    const wp = getMatchingCategories(
      updateStockValues.SubCategory,
      flattenCategories(categories.worldPostal)
    );
    setGBPH(gbph);
    setGS(gs);
    setWP(wp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleSearchOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOrderValue((event.target as HTMLInputElement).value);
  };

  const handleSearch = () => {
    // will on search the current page the user is on will fix with search api
    const searchVal = searchRef.current?.value.toLowerCase();
    setSearchResults([]);
    if (searchVal) {
      const searchFilter =
        searchOrderValue === "by reference number" ? "reference" : "title";
      setLoading(true);
      const auth = getAuth();

      fetchWithTokenCheck(
        {
          method: "GET",
          url: `${AppConfig.apiUrl}/stock?${searchFilter}=${searchVal}`,
        },
        auth
      )
        .then((res) => {
          setSearchResults(res.data.stock);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.warn(err);
        });
    } else {
      fetchStock();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleAddMenu = () => {
    setAddOpen(!addOpen);
    setUpdateStockValues(initialStockState);
    setNewStockValues(newInitialStockState);
    setGBPH([]);
    setWP([]);
    setGS([]);
    setSelectedImage(null);
    setUploadImage(null);
    setExtraImages([]);
    setSelectedExtraImages([]);
    setShowTitleColorPicker(false);
    setShowDescColorPicker(false);
    setCurrentColor("#000000");
  };

  const handleUpdateMenu = () => {
    setUpdateOpen(!updateOpen);
    setUpdateStockValues(initialStockState);
    setNewStockValues(newInitialStockState);
    setGBPH([]);
    setWP([]);
    setGS([]);
    setSelectedImage(null);
    setUploadImage(null);
    setExtraImages([]);
    setSelectedExtraImages([]);
    setShowTitleColorPicker(false);
    setShowDescColorPicker(false);
    setCurrentColor("#000000");
  };

  const handleAddStock = (event: React.FormEvent) => {
    event.preventDefault();

    // Validate price before proceeding
    if (parseFloat(newStockValues.Price.toString()) <= 0) {
      toast.error("Price must be greater than 0");
      return;
    }

    const gbphString = gbph.map((a) => a.ID).join(" | ");
    const gsString = gs.map((a) => a.ID).join(" | ");
    const wpString = wp.map((a) => a.ID).join(" | ");

    const finalString = [gbphString, gsString, wpString]
      .filter((str) => str)
      .join(" | ");

    const newStock = {
      ...newStockValues,
      Title: draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
      Description: draftToHtml(
        convertToRaw(descEditorState.getCurrentContent())
      ),
      SubCategory: finalString,
      Price: parseFloat(newStockValues.Price.toString()),
      Reference: newStockValues.Reference,
      AddDate: Math.floor(Date.now() / 1000).toString(), // epoch seconds
      ItemState: frontPageItem ? "on" : "",
      Quantity: checkQuantity(newStockValues.Quantity),
      IsSheet: isSheet,
    };

    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "POST",
        url: `${AppConfig.apiUrl}/products`,
        data: newStock,
      },
      auth
    )
      .then((res) => {
        handleUploadImage(res.data.productId);
        toast.success(`Created New Item`);
        setAddOpen(!addOpen);
        fetchStock();
      })
      .catch((err) => console.warn(err));
  };

  const handleUpdateStock = (event: React.FormEvent) => {
    event.preventDefault();

    // Validate price before proceeding
    if (parseFloat(updateStockValues.Price.toString()) <= 0) {
      toast.error("Price must be greater than 0");
      return;
    }

    const gbphString = gbph.map((a) => a.ID).join(" | ");
    const gsString = gs.map((a) => a.ID).join(" | ");
    const wpString = wp.map((a) => a.ID).join(" | ");

    const finalString = [gbphString, gsString, wpString]
      .filter((str) => str)
      .join(" | ");

    const stockUpdate = {
      ...updateStockValues,
      Title: draftToHtml(convertToRaw(titleEditorState.getCurrentContent())),
      Description: draftToHtml(
        convertToRaw(descEditorState.getCurrentContent())
      ),
      SubCategory: finalString,
      Price: parseFloat(updateStockValues.Price.toString()),
      Reference: updateStockValues.Reference,
      ItemState: frontPageItem ? "on" : "",
      Quantity: checkQuantity(updateStockValues.Quantity),
      IsSheet: isSheet,
    };

    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "PUT",
        url: `${AppConfig.apiUrl}/products/${stockUpdate.ID}`,
        data: {
          ...stockUpdate,
        },
      },
      auth
    )
      .then(() => {
        toast.success(
          `Updated Reference ${updateStockValues.Reference}'s Information`
        );
        setUpdateOpen(!updateOpen);
        handleUploadImage(updateStockValues.ID);
        fetchStock();
      })
      .catch((err) => console.warn(err));
  };

  const handleUploadImage = (id: number) => {
    const allImages = [];
    const auth = getAuth();

    if (uploadImage) {
      const newFileName = `${id}.jpg`;

      const fileToDelete = selectedExtraImages.find(
        (a) => a.Reference === newFileName
      );

      fileToDelete &&
        fetchWithTokenCheck(
          {
            method: "DELETE",
            url: `${AppConfig.apiUrl}/deleteImage/${fileToDelete.Id}`,
          },
          auth
        );

      const mainImageFile = new File([uploadImage], newFileName, {
        type: uploadImage.type,
        lastModified: uploadImage.lastModified,
      });

      allImages.push({ filename: newFileName, file: mainImageFile });
    }

    const existingSuffixes = selectedExtraImages
      .map((image) => {
        const match = image.Reference.match(/-(\d+)\.jpg$/);
        return match ? parseInt(match[1], 10) : 0;
      })
      .sort((a, b) => b - a);

    let nextSuffix = existingSuffixes.length > 0 ? existingSuffixes[0] + 1 : 1;

    extraImages.forEach((image) => {
      const extraFileName = `${id}-${nextSuffix}.jpg`;

      const extraImageFile = new File([image], extraFileName, {
        type: image.type,
        lastModified: image.lastModified,
      });

      allImages.push({ filename: extraFileName, file: extraImageFile });
      nextSuffix += 1;
    });

    if (allImages.length > 0) {
      submitImage(allImages, id);
    }

    setUploadImage(null);
    setSelectedImage(null);
    setExtraImages([]);
  };

  const submitImage = useCallback(
    async (images: { filename: string; file: File }[], id: number) => {
      const auth = getAuth();

      const requests = await Promise.all(
        images.map((imageObj) => {
          return new Promise<{ filename: string; image: string }>((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              if (!e.target?.result) return;
              const base64Image = btoa(e.target.result as string);

              resolve({
                filename: imageObj.filename,
                image: base64Image,
              });
            };
            reader.onerror = (e) => {
              console.error("Unable to read file", e);
            };
            reader.readAsBinaryString(imageObj.file);
          });
        })
      );

      const requestBody = {
        product_id: id.toString(),
        files: requests,
      };

      try {
        await fetchWithTokenCheck(
          {
            method: "POST",
            url: `${AppConfig.apiUrl}/upload`,
            data: JSON.stringify(requestBody),
            headers: {
              "Content-Type": "application/json",
            },
          },
          auth
        );
        toast.success("Uploaded Images");
      } catch {
        toast.error("Failed to upload Images");
      }
    },
    []
  );

  const handleAddStockChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    const { name, value } = e.target;
    setNewStockValues({ ...newStockValues, [name]: value });
  };

  const handleUpdateStockChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    const { name, value } = e.target;
    setUpdateStockValues({ ...updateStockValues, [name]: value });
  };

  const handleRowClick = async (params: any) => {
    setFrontPageItem(false);
    setTitleEditorState(EditorState.createEmpty());
    setDescEditorState(EditorState.createEmpty());
    const singleStock = fetchSingleStock(params).then((res: StockT) => {
      const rawTitle = cleanHtmlString(res.Title);
      const rawDesc = cleanHtmlString(res.Description);

      const cleanTitle = htmlToDraft(rawTitle);
      const cleanDesc = htmlToDraft(rawDesc);
      const titleContent = ContentState.createFromBlockArray(
        cleanTitle.contentBlocks
      );
      const descContent = ContentState.createFromBlockArray(
        cleanDesc.contentBlocks
      );
      const title = EditorState.createWithContent(titleContent);
      const desc = EditorState.createWithContent(descContent);
      setTitleEditorState(title);
      setDescEditorState(desc);
      setFrontPageItem(res.ItemState === "on" ? true : false);
      setIsSheet(res.IsSheet);
      return res;
    });
    setUpdateStockValues((await singleStock) || initialStockState);
    //Update to new fetch once everything else is organised
  };

  const columns: GridColDef[] = [
    {
      field: "Reference",
      headerName: "Reference",
      width: 100,
      sortable: false,
      filterable: false,
    },
    {
      field: "ID",
      headerName: "Image",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <img
            src={`${AppConfig.smImgUrl}/${params.row.ID}.jpg`}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        );
      },
    },
    {
      field: "Title",
      headerName: "Title",
      sortable: false,
      filterable: false,
      width: 500,
      valueGetter: (params) => {
        return parseHTMLString(params.row.Title);
      },
    },
    {
      field: "AddDate",
      headerName: "Date",
      width: 120,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const date = new Date(Number(params.row.AddDate * 1000)).toISOString();
        return dayjs(date).format("DD-MM-YYYY");
      },
    },
    {
      field: "Price",
      headerName: "Price",
      width: 100,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return `£${Number(params.row.Price).toFixed(2)}`;
      },
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              color:
                params.row.Quantity === "0" || !params.row.Quantity
                  ? "red"
                  : "inherit",
            }}
          >
            {params.row.Quantity}
          </div>
        );
      },
    },
  ];

  const getId = (row: StockT) => {
    return row.ID;
  };

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const handleColorChange = (c: any) => {
    const newColor = "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a);
    setCurrentColor(newColor);
    setTitleEditorState(
      toggleCustomInlineStyle(titleEditorState, "color", newColor)
    );
    setDescEditorState(
      toggleCustomInlineStyle(descEditorState, "color", newColor)
    );
  };

  const handleLineBreak = (editorState: any, setEditorState: any) => {
    return () => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "\u00A0", // Unicode for non-breaking space
        editorState.getCurrentInlineStyle()
      );

      setEditorState(
        EditorState.push(editorState, contentState, "insert-characters")
      );
    };
  };

  const handleAdminCallback = (categoryId: number, name: string) => {
    // Determine which category list this belongs to
    // We need to find which category collection this ID belongs to
    const gbphCategory = flattenCategories(categories.gbPostalHistory).find(
      (cat) => cat.ID === categoryId
    );
    const gsCategory = flattenCategories(categories.gbStamps).find(
      (cat) => cat.ID === categoryId
    );
    const wpCategory = flattenCategories(categories.worldPostal).find(
      (cat) => cat.ID === categoryId
    );

    if (gbphCategory) {
      // Check if this category is already selected
      const isAlreadySelected = gbph.some((cat) => cat.ID === categoryId);
      if (isAlreadySelected) {
        // Remove it if already selected
        setGBPH(gbph.filter((cat) => cat.ID !== categoryId));
      } else {
        // Add it if not already selected
        setGBPH([...gbph, gbphCategory]);
      }
    } else if (gsCategory) {
      const isAlreadySelected = gs.some((cat) => cat.ID === categoryId);
      if (isAlreadySelected) {
        setGS(gs.filter((cat) => cat.ID !== categoryId));
      } else {
        setGS([...gs, gsCategory]);
      }
    } else if (wpCategory) {
      const isAlreadySelected = wp.some((cat) => cat.ID === categoryId);
      if (isAlreadySelected) {
        setWP(wp.filter((cat) => cat.ID !== categoryId));
      } else {
        setWP([...wp, wpCategory]);
      }
    }
  };

  return (
    <div className={styles.stockContainer}>
      <h2>Stock Management</h2>
      <div>
        <OutlinedInput
          style={{ marginRight: "1rem" }}
          size="small"
          name="Search for stock..."
          placeholder="Search for stock..."
          inputRef={searchRef}
          onKeyDown={handleKeyDown}
          type="text"
        />
        <FormControl>
          <RadioGroup
            row
            value={searchOrderValue}
            name="searchOrderValue"
            id="searchBox"
            onChange={handleSearchOrderChange}
          >
            <FormControlLabel
              value="by reference number"
              control={<Radio />}
              label="by reference number"
            />
            <FormControlLabel
              value="by title"
              control={<Radio />}
              label="by title"
            />
          </RadioGroup>
        </FormControl>
        <Button
          style={{ marginRight: "1rem" }}
          variant="contained"
          onClick={handleSearch}
        >
          Search
        </Button>

        <Button variant="contained" color="success" onClick={handleAddMenu}>
          Add new item
        </Button>
      </div>
      {loading ? (
        <div className={styles.inlineLoader}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.stockTable}>
            {" "}
            <DataGrid
              getRowId={getId}
              rows={
                searchResults && searchResults.length > 0
                  ? searchResults
                  : stock
              }
              columns={columns}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              loading={loading}
              rowCount={totalCount}
              paginationModel={{
                page: page,
                pageSize: pageSize,
              }}
              onRowClick={(params) => {
                handleRowClick(params.id);
                handleUpdateMenu();
              }}
              onPaginationModelChange={handlePaginationModelChange}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
            />
          </div>
        </>
      )}
      {/* Update Stock Dialog */}
      <Dialog fullScreen open={updateOpen} onClose={handleUpdateMenu}>
        <AppBar
          sx={{
            position: "relative",
            "@media print": {
              display: "none",
            },
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateMenu}
              aria-label="close"
            >
              <GridCloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            ></Typography>
            <Button autoFocus color="inherit" onClick={handleUpdateStock}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form onSubmit={handleUpdateStock}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        disabled
                        id="Reference"
                        name="Reference"
                        label="Reference"
                        size="small"
                        placeholder="Reference"
                        value={updateStockValues.Reference}
                        onChange={handleUpdateStockChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="Price"
                        name="Price"
                        label="Price"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              &pound;
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Price"
                        value={updateStockValues.Price}
                        onChange={handleUpdateStockChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Navigation
                      admin={true}
                      adminCallback={handleAdminCallback}
                      selectedCategories={[...gbph, ...gs, ...wp].map(
                        (cat) => cat.ID
                      )}
                    />
                  </Grid>

                  {/* Display selected categories */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Selected Categories:
                    </Typography>
                    <div className={styles.selectedCategories}>
                      {gbph.length > 0 && (
                        <div>
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            GB Postal History:
                          </Typography>
                          <ul>
                            {gbph.map((cat) => (
                              <li key={`gbph-${cat.ID}`}>
                                {cat.Name}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setGBPH(gbph.filter((c) => c.ID !== cat.ID))
                                  }
                                >
                                  <GridCloseIcon fontSize="small" />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {gs.length > 0 && (
                        <div>
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            GB Stamps:
                          </Typography>
                          <ul>
                            {gs.map((cat) => (
                              <li key={`gs-${cat.ID}`}>
                                {cat.Name}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setGS(gs.filter((c) => c.ID !== cat.ID))
                                  }
                                >
                                  <GridCloseIcon fontSize="small" />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {wp.length > 0 && (
                        <div>
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            World Postal:
                          </Typography>
                          <ul>
                            {wp.map((cat) => (
                              <li key={`wp-${cat.ID}`}>
                                {cat.Name}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setWP(wp.filter((c) => c.ID !== cat.ID))
                                  }
                                >
                                  <GridCloseIcon fontSize="small" />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="Quantity"
                        name="Quantity"
                        label="Quantity"
                        size="small"
                        placeholder="Quantity"
                        value={updateStockValues.Quantity}
                        onChange={handleUpdateStockChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={frontPageItem}
                            onChange={() => setFrontPageItem(!frontPageItem)}
                          />
                        }
                        label="Front page item"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSheet}
                            onChange={() => setIsSheet(!isSheet)}
                          />
                        }
                        label="Is Sheet"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <ImageUploadButton
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      setUploadImage={setUploadImage}
                      extraImages={extraImages}
                      setExtraImages={setExtraImages}
                      selectedExtraImages={selectedExtraImages}
                      setSelectedExtraImages={setSelectedExtraImages}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <div>Title</div>
                    <Editor
                      // customBlockRenderFunc={myBlockStyleFn}
                      editorStyle={{ fontFamily: "Book Antiqua" }}
                      editorState={titleEditorState}
                      onEditorStateChange={setTitleEditorState}
                      editorClassName={styles.editorContainer}
                      spellCheck
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "image",
                          "remove",
                          "history",
                        ],
                        fontFamily: {
                          ...CustomToolbar().fontFamily,
                          inDropdown: true,
                        },
                        fontSize: {
                          default: 14,
                          options: [
                            8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28,
                            30, 36, 48, 60, 72, 96,
                          ],
                        },
                        colorPicker: {
                          colors: editorColours,
                        },
                      }}
                      toolbarCustomButtons={[
                        <div key="color-picker">
                          <button
                            type="button"
                            onClick={() =>
                              setShowTitleColorPicker(!showTitleColorPicker)
                            }
                            style={{
                              backgroundColor: currentColor,
                              border: "none",
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                            }}
                          />
                          {showTitleColorPicker && (
                            <Draggable
                              handle=".color-picker-handle"
                              bounds="body"
                              defaultPosition={{ x: 0, y: 0 }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  maxWidth: "100vw",
                                  maxHeight: "100vh",
                                }}
                              >
                                <div
                                  className="color-picker-handle"
                                  style={{
                                    padding: "8px",
                                    background: "#f0f0f0",
                                    cursor: "move",
                                    borderRadius: "4px 4px 0 0",
                                  }}
                                >
                                  Drag to move
                                </div>
                                <SketchPicker
                                  color={currentColor}
                                  onChange={(color: ColorResult) =>
                                    handleColorChange(color)
                                  }
                                />
                              </div>
                            </Draggable>
                          )}
                        </div>,
                        <div key="line-break">
                          <button
                            className="rdw-option-wrapper"
                            style={{ height: "80%" }}
                            onClick={handleLineBreak(
                              titleEditorState,
                              setTitleEditorState
                            )}
                            type="button"
                          >
                            Space
                          </button>
                        </div>,
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>Description</div>
                    <Editor
                      // customBlockRenderFunc={myBlockStyleFn}
                      editorStyle={{ fontFamily: "Book Antiqua" }}
                      editorState={descEditorState}
                      onEditorStateChange={setDescEditorState}
                      editorClassName={styles.editorContainer}
                      spellCheck
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "image",
                          "remove",
                          "history",
                        ],
                        fontFamily: {
                          ...CustomToolbar().fontFamily,
                          inDropdown: true,
                        },
                        fontSize: {
                          default: 14,
                          options: [
                            8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28,
                            30, 36, 48, 60, 72, 96,
                          ],
                        },
                        colorPicker: {
                          colors: editorColours,
                        },
                      }}
                      toolbarCustomButtons={[
                        <div key="color-picker">
                          <button
                            type="button"
                            onClick={() =>
                              setShowDescColorPicker(!showDescColorPicker)
                            }
                            style={{
                              backgroundColor: currentColor,
                              border: "none",
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                            }}
                          />
                          {showDescColorPicker && (
                            <Draggable
                              handle=".color-picker-handle"
                              bounds="body"
                              defaultPosition={{ x: 0, y: 0 }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  maxWidth: "100vw",
                                  maxHeight: "100vh",
                                }}
                              >
                                <div
                                  className="color-picker-handle"
                                  style={{
                                    padding: "8px",
                                    background: "#f0f0f0",
                                    cursor: "move",
                                    borderRadius: "4px 4px 0 0",
                                  }}
                                >
                                  Drag to move
                                </div>
                                <SketchPicker
                                  color={currentColor}
                                  onChange={(color: ColorResult) =>
                                    handleColorChange(color)
                                  }
                                />
                              </div>
                            </Draggable>
                          )}
                        </div>,
                        <div key="line-break">
                          <button
                            className="rdw-option-wrapper"
                            style={{ height: "80%" }}
                            onClick={handleLineBreak(
                              titleEditorState,
                              setTitleEditorState
                            )}
                            type="button"
                          >
                            Space
                          </button>
                        </div>,
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Button
                    style={{ marginRight: "1rem" }}
                    type="submit"
                    color="success"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginRight: "1rem" }}
                    onClick={handleUpdateMenu}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {/* Add Stock Dialog */}
      <Dialog fullScreen open={addOpen} onClose={handleAddMenu}>
        <AppBar
          sx={{
            position: "relative",
            "@media print": {
              display: "none",
            },
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddMenu}
              aria-label="close"
            >
              <GridCloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            ></Typography>
            <Button autoFocus color="inherit" onClick={handleUpdateStock}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={styles.lastNumberUsed}>
            Last number used: {latestReference}
          </div>
          <form onSubmit={handleAddStock}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        disabled
                        id="Reference"
                        name="Reference"
                        label="Reference"
                        size="small"
                        placeholder="Reference"
                        value={newStockValues.Reference}
                        onChange={handleAddStockChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="Price"
                        name="Price"
                        label="Price"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              &pound;
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Price"
                        value={newStockValues.Price}
                        onChange={handleAddStockChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Navigation
                      admin={true}
                      adminCallback={handleAdminCallback}
                      selectedCategories={[...gbph, ...gs, ...wp].map(
                        (cat) => cat.ID
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Selected Categories:
                    </Typography>
                    <div className={styles.selectedCategories}>
                      {gbph.length > 0 && (
                        <div>
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            GB Postal History:
                          </Typography>
                          <ul>
                            {gbph.map((cat) => (
                              <li key={`gbph-${cat.ID}`}>
                                {cat.Name}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setGBPH(gbph.filter((c) => c.ID !== cat.ID))
                                  }
                                >
                                  <GridCloseIcon fontSize="small" />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {gs.length > 0 && (
                        <div>
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            GB Stamps:
                          </Typography>
                          <ul>
                            {gs.map((cat) => (
                              <li key={`gs-${cat.ID}`}>
                                {cat.Name}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setGS(gs.filter((c) => c.ID !== cat.ID))
                                  }
                                >
                                  <GridCloseIcon fontSize="small" />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {wp.length > 0 && (
                        <div>
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            World Postal:
                          </Typography>
                          <ul>
                            {wp.map((cat) => (
                              <li key={`wp-${cat.ID}`}>
                                {cat.Name}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setWP(wp.filter((c) => c.ID !== cat.ID))
                                  }
                                >
                                  <GridCloseIcon fontSize="small" />
                                </IconButton>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="Quantity"
                        name="Quantity"
                        label="Quantity"
                        size="small"
                        placeholder="Quantity"
                        value={newStockValues.Quantity}
                        onChange={handleAddStockChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={frontPageItem}
                            onChange={() => setFrontPageItem(!frontPageItem)}
                          />
                        }
                        label="Front page item"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSheet}
                            onChange={() => setIsSheet(!isSheet)}
                          />
                        }
                        label="Is Sheet"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <ImageUploadButton
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      setUploadImage={setUploadImage}
                      extraImages={extraImages}
                      setExtraImages={setExtraImages}
                      selectedExtraImages={selectedExtraImages}
                      setSelectedExtraImages={setSelectedExtraImages}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <div>Title</div>
                    <Editor
                      // customBlockRenderFunc={myBlockStyleFn}
                      editorStyle={{ fontFamily: "Book Antiqua" }}
                      editorState={titleEditorState}
                      onEditorStateChange={setTitleEditorState}
                      editorClassName={styles.editorContainer}
                      spellCheck
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "image",
                          "remove",
                          "history",
                        ],
                        fontFamily: {
                          ...CustomToolbar().fontFamily,
                          inDropdown: true,
                        },
                        fontSize: {
                          default: 14,
                          options: [
                            8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28,
                            30, 36, 48, 60, 72, 96,
                          ],
                        },
                        colorPicker: {
                          colors: editorColours,
                        },
                      }}
                      toolbarCustomButtons={[
                        <div key="color-picker">
                          <button
                            type="button"
                            onClick={() =>
                              setShowTitleColorPicker(!showTitleColorPicker)
                            }
                            style={{
                              backgroundColor: currentColor,
                              border: "none",
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                            }}
                          />
                          {showTitleColorPicker && (
                            <Draggable
                              handle=".color-picker-handle"
                              bounds="body"
                              defaultPosition={{ x: 0, y: 0 }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  maxWidth: "100vw",
                                  maxHeight: "100vh",
                                }}
                              >
                                <div
                                  className="color-picker-handle"
                                  style={{
                                    padding: "8px",
                                    background: "#f0f0f0",
                                    cursor: "move",
                                    borderRadius: "4px 4px 0 0",
                                  }}
                                >
                                  Drag to move
                                </div>
                                <SketchPicker
                                  color={currentColor}
                                  onChange={(color: ColorResult) =>
                                    handleColorChange(color)
                                  }
                                />
                              </div>
                            </Draggable>
                          )}
                        </div>,
                        <div key="line-break">
                          <button
                            className="rdw-option-wrapper"
                            style={{ height: "80%" }}
                            onClick={handleLineBreak(
                              titleEditorState,
                              setTitleEditorState
                            )}
                            type="button"
                          >
                            Space
                          </button>
                        </div>,
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>Description</div>
                    <Editor
                      // customBlockRenderFunc={myBlockStyleFn}
                      editorStyle={{ fontFamily: "Book Antiqua" }}
                      editorState={descEditorState}
                      onEditorStateChange={setDescEditorState}
                      editorClassName={styles.editorContainer}
                      spellCheck
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "image",
                          "remove",
                          "history",
                        ],
                        fontFamily: {
                          ...CustomToolbar().fontFamily,
                          inDropdown: true,
                        },
                        fontSize: {
                          default: 14,
                          options: [
                            8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28,
                            30, 36, 48, 60, 72, 96,
                          ],
                        },
                        colorPicker: {
                          colors: editorColours,
                        },
                      }}
                      toolbarCustomButtons={[
                        <div key="color-picker">
                          <button
                            type="button"
                            onClick={() =>
                              setShowDescColorPicker(!showDescColorPicker)
                            }
                            style={{
                              backgroundColor: currentColor,
                              border: "none",
                              width: 30,
                              height: 30,
                              cursor: "pointer",
                            }}
                          />
                          {showDescColorPicker && (
                            <Draggable
                              handle=".color-picker-handle"
                              bounds="body"
                              defaultPosition={{ x: 0, y: 0 }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  maxWidth: "100vw",
                                  maxHeight: "100vh",
                                }}
                              >
                                <div
                                  className="color-picker-handle"
                                  style={{
                                    padding: "8px",
                                    background: "#f0f0f0",
                                    cursor: "move",
                                    borderRadius: "4px 4px 0 0",
                                  }}
                                >
                                  Drag to move
                                </div>
                                <SketchPicker
                                  color={currentColor}
                                  onChange={(color: ColorResult) =>
                                    handleColorChange(color)
                                  }
                                />
                              </div>
                            </Draggable>
                          )}
                        </div>,
                        <div key="line-break">
                          <button
                            className="rdw-option-wrapper"
                            style={{ height: "80%" }}
                            onClick={handleLineBreak(
                              titleEditorState,
                              setTitleEditorState
                            )}
                            type="button"
                          >
                            Space
                          </button>
                        </div>,
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Button
                    style={{ marginRight: "1rem" }}
                    type="submit"
                    color="success"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginRight: "1rem" }}
                    onClick={handleAddMenu}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ImageUploadButton = ({
  selectedImage,
  setSelectedImage,
  setUploadImage,
  extraImages,
  setExtraImages,
  selectedExtraImages,
  setSelectedExtraImages,
}: {
  selectedImage: string | null;
  setSelectedImage: Dispatch<SetStateAction<string | null>>;
  setUploadImage: Dispatch<SetStateAction<any>>;
  extraImages: File[];
  setExtraImages: Dispatch<SetStateAction<File[]>>;
  selectedExtraImages: Image[];
  setSelectedExtraImages: Dispatch<SetStateAction<Image[]>>;
}) => {
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      setUploadImage(event.target.files[0]);
    }
  };

  const handleExtraImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      setExtraImages((prev) => [...prev, newImage]);
    }
  };

  const handleDeleteExtraImage = (
    index: number,
    isNewImage: boolean,
    id: number
  ) => {
    const auth = getAuth();

    if (isNewImage) {
      setExtraImages((prev) => prev.filter((_, i) => i !== index));
    } else {
      setSelectedExtraImages((prev) => prev.filter((_, i) => i !== index));
      fetchWithTokenCheck(
        {
          method: "DELETE",
          url: `${AppConfig.apiUrl}/deleteImage/${id}`,
        },
        auth
      );
    }
  };

  return (
    <div className={styles.imageUploadButtons}>
      <div>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Upload Main Image
          </Button>
        </label>
        {selectedImage && (
          <div style={{ marginTop: "20px" }}>
            <img
              src={selectedImage}
              alt="Selected"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
      </div>
      <div>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="extra-button-file"
          type="file"
          onChange={handleExtraImageChange}
        />
        <label htmlFor="extra-button-file">
          <Button variant="contained" component="span">
            Upload Extra Images
          </Button>
        </label>

        {(extraImages.length > 0 || selectedExtraImages.length > 0) && (
          <div style={{ marginTop: "20px" }}>
            {selectedExtraImages
              .filter((a: Image) => a.Reference.includes("-"))
              .map((image, index) => (
                <div
                  key={`existing-${index}`}
                  style={{
                    marginBottom: "10px",
                    alignItems: "center",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={`${AppConfig.smImgUrl}/${image.Reference}`}
                    alt={`Extra ${index + 1}`}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleDeleteExtraImage(index, false, image.Id)
                    }
                  >
                    Remove
                  </Button>
                </div>
              ))}
            {extraImages.map((image, index) => (
              <div
                key={`new-${index}`}
                style={{
                  marginBottom: "10px",
                  alignItems: "center",
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Extra ${index + 1}`}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeleteExtraImage(index, true, 0)}
                >
                  Remove
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
