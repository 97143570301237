import { useEffect, useState } from "react";
import { auth, signInWithEmail } from "../../index";
import Loading from "../../components/Loader/Loader";
import styles from "./Admin.module.css";
import { Button } from "@mui/material";
import useAppState from "../../hooks/useAppState";
import AdminNavigation from "../../components/AdminNavigation/AdminNavigation";

export default function Admin({ children }: any) {
  const { userAuth, setUserAuth } = useAppState();
  const [authLoading, setAuthLoading] = useState(true);
  const [inputName, setInputName] = useState("email");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const onChangeInput = (event: any) => {
    const inputVal = event.target.value;

    setFormData((oldForm) => ({
      ...oldForm,
      [inputName]: inputVal,
    }));
  };

  const getInputValue = (inputName: keyof typeof formData) => {
    return formData[inputName] || "";
  };

  useEffect(() => {
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        setUserAuth(user);
        const token = await user.getIdToken();
        localStorage.setItem("api-token", token);
        setAuthLoading(false);
      } else {
        setAuthLoading(false);
      }
    });

    const refreshToken = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const newToken = await currentUser.getIdToken();
          localStorage.setItem("api-token", newToken);
        }
      } catch (error) {
        console.error("Token refresh failed:", error);
      }
    };

    // Handle visibility change (when user returns from sleep/tab switch)
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refreshToken();
      }
    };

    // Initial token refresh
    refreshToken();

    // Set up interval for token refresh
    const intervalId = setInterval(refreshToken, 45 * 60 * 1000);

    // Add visibility change listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAllow = () => {
    userAuth?.getIdToken().then((a) => {
      localStorage.setItem("api-token", a);
    });
    return userAuth?.email;
  };

  const handleSignIn = () => {
    setAuthLoading(true);
    signInWithEmail(formData.email, formData.password)
      .then(() => setAuthLoading(false))
      .catch(() => setAuthLoading(false));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  if (authLoading) {
    return (
      <>
        <Loading fullscreen />
      </>
    );
  }

  return (
    <>
      {isAllow() ? (
        <div className={styles.adminContainer}>
          <AdminNavigation />
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            {children}
          </div>
        </div>
      ) : (
        <div className={styles.loginScreen}>
          <div className={styles.loginHeader}>
            Martin Townsend - Seller Login
          </div>
          <div style={{ marginTop: "10px" }}>Seller Login</div>
          <div className={styles.loginForm}>
            <input
              type="email"
              className={styles.loginInput}
              placeholder="Email"
              value={getInputValue("email")}
              onChange={onChangeInput}
              onFocus={() => setInputName("email")}
              onKeyDown={handleKeyDown}
            />
            <input
              type="password"
              className={styles.loginInput}
              placeholder="Password"
              value={getInputValue("password")}
              onChange={onChangeInput}
              onFocus={() => setInputName("password")}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles.loginSubmit}>
            <Button
              style={{ width: "40%" }}
              color="info"
              variant="contained"
              onClick={() => handleSignIn()}
            >
              Sign In
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
