import type { User } from "firebase/auth";
import { createContext, useContext, useMemo, useState } from "react";
import type { Product } from "../pages/Home/Home";

interface AppStateContextInterface {
  userAuth: User | null;
  setUserAuth: (value: React.SetStateAction<User | null>) => void;
  shoppingCart: Product[] | [];
  setShoppingCart: (value: React.SetStateAction<Product[]>) => void;
  selectedCategoryName: string;
  setSelectedCategoryName: (value: string) => void;
  selectedPage: number;
  setSelectedPage: (value: number) => void;
}

export const AppStateContext = createContext<AppStateContextInterface>({
  userAuth: null,
  setUserAuth: () => {},
  shoppingCart: [],
  setShoppingCart: () => {},
  selectedCategoryName: "",
  setSelectedCategoryName: () => {},
  selectedPage: 1,
  setSelectedPage: () => {},
});

interface Props {
  children: React.ReactNode;
}

export function AppStateProvider({ children }: Props) {
  const [userAuth, setUserAuth] = useState<User | null>(null);
  const [shoppingCart, setShoppingCart] = useState<Product[] | []>([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const memorizedValues = useMemo(
    () => ({
      userAuth,
      setUserAuth,
      shoppingCart,
      setShoppingCart,
      selectedCategoryName,
      setSelectedCategoryName,
      selectedPage,
      setSelectedPage,
    }),
    [userAuth, shoppingCart, selectedCategoryName, selectedPage]
  );

  return (
    <AppStateContext.Provider
      value={{
        ...memorizedValues,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

export default function useAppState() {
  return useContext(AppStateContext);
}
