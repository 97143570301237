export const AppConfig = {
  stripeKeyTest: "pk_test_lcmyOYPc2XCv7GmdBukVH2OO",
  stripeKeyProd: "pk_live_eaPW0AN08DQkgCcIwFfl82IQ",
  apiUrl:
    process.env.API_URL ?? "https://martintownsend-flzjpgn2ya-nw.a.run.app",
  smImgUrl:
    process.env.SMALL_IMG_URL ??
    "https://imgproxy.martintownsend.com/insecure/resize:fit:200:160/plain/gs://martin-townsend-images",
  lgImgUrl:
    process.env.BIG_IMG_URL ??
    "https://imgproxy.martintownsend.com/insecure/resize:fit:800/plain/gs://martin-townsend-images",
};
