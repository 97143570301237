import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Edit, Add, Delete } from "@mui/icons-material";
import styles from "./Category.module.css";
import Loading from "../../../components/Loader/Loader";
import { AppConfig } from "../../../utils/config";
import {
  NodeRendererProps,
  SimpleTreeData,
  Tree,
  TreeApi,
  MoveHandler,
} from "react-arborist";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import { fetchWithTokenCheck } from "../../../utils/utils";

export type RegionEntry = {
  ID: number;
  Name: string;
  Region: string;
  Reference: string;
  Xcoord: string;
  Ycoord: string;
  Parent: string;
  SortOrder: number;
};

export type CategoriesList = {
  [key: string]: RegionEntry[];
};

type AdminSubCategories = {
  id: number;
  name: string;
  region: number;
  parent: number;
  sortOrder: number;
};

export type AdminCategoriesList = {
  id: number;
  region: number;
  name: string;
  categories: AdminSubCategories[];
}[];

const parentRegionMapping = {
  "GB Postal History": 1,
  "GB Stamps": 2,
  "World Postal History": 3,
};

export default function Category() {
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<{
    gbPostalHistory: AdminCategoriesList;
    gbStamps: AdminCategoriesList;
    worldPostal: AdminCategoriesList;
  }>({
    gbPostalHistory: [],
    gbStamps: [],
    worldPostal: [],
  });
  const [transformedData, setTransformedData] = useState<SimpleTreeData[]>([]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const allCategories = await axios.get(`${AppConfig.apiUrl}/categories`);

      const res1 = allCategories.data.filter(
        (a: AdminSubCategories) => a.parent === 1
      );
      const res2 = allCategories.data.filter(
        (a: AdminSubCategories) => a.parent === 2
      );
      const res3 = allCategories.data.filter(
        (a: AdminSubCategories) => a.parent === 3
      );

      setCategories({
        gbPostalHistory: res1,
        gbStamps: res2,
        worldPostal: res3,
      });
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (Object.keys(categories).length > 0) {
      const data = transformDataToTree(categories);
      setTransformedData(data);
    }
  }, [categories]);

  return (
    <div>
      <h2>Category Management</h2>
      {loading ? (
        <div className={styles.inlineLoader}>
          <Loading />
        </div>
      ) : (
        <div>
          {transformedData.length > 0 && (
            <EditableTree
              initialData={transformedData}
              fetchCategories={fetchCategories}
            />
          )}
        </div>
      )}
    </div>
  );
}

const transformDataToTree = (categories: {
  gbPostalHistory: AdminCategoriesList;
  gbStamps: AdminCategoriesList;
  worldPostal: AdminCategoriesList;
}): SimpleTreeData[] => {
  const createTree = (
    data: AdminCategoriesList,
    title: string
  ): SimpleTreeData => {
    const children = data.map((item) => ({
      id: `${item.id}-master`,
      name: item.name,
      children: item.categories
        .map((i: AdminSubCategories) => ({
          ...i,
          id: `${i.id.toString()}`,
          name: i.name,
        }))
        .sort((a, b) => a.sortOrder - b.sortOrder),
    }));

    return {
      id: title,
      name: title,
      children,
    };
  };

  return [
    createTree(categories.gbPostalHistory, "GB Postal History"),
    createTree(categories.gbStamps, "GB Stamps"),
    createTree(categories.worldPostal, "World Postal History"),
  ];
};

const reverseTransformData = (treeData: SimpleTreeData[]) => {
  const createCategoryList = (
    tree: SimpleTreeData[],
    parentRegion: number
  ): AdminSubCategories[] => {
    const categoryList: AdminSubCategories[] = [];
    tree.forEach((node, index) => {
      const items = node.children?.map((child, childIndex) => ({
        id: parseInt(child.id),
        name: child.name,
        region: parentRegion,
        parent: Number(node.id.split("-")[0]),
        sortOrder: childIndex,
      }));
      items && categoryList.push(...items);
    });
    return categoryList;
  };

  return {
    gbPostalHistory: createCategoryList(
      treeData.find((tree) => tree.id === "GB Postal History")?.children || [],
      1
    ),
    gbStamps: createCategoryList(
      treeData.find((tree) => tree.id === "GB Stamps")?.children || [],
      2
    ),
    worldPostal: createCategoryList(
      treeData.find((tree) => tree.id === "World Postal History")?.children ||
        [],
      3
    ),
  };
};

const EditableTree = ({
  initialData,
  fetchCategories,
}: {
  initialData: SimpleTreeData[];
  fetchCategories: () => void;
}) => {
  const [treeData, setTreeData] = useState<SimpleTreeData[]>(initialData);
  const treeApi = useRef<TreeApi<SimpleTreeData>>(null);
  const [removeNodeOpen, setRemoveNodeOpen] = useState<boolean>(false);
  const [nodeToRemove, setNodeToRemove] = useState<any>({});
  const [confirmationText, setConfirmationText] = useState("");

  useEffect(() => {
    setTreeData(initialData);
  }, [initialData]);

  const handleAddNode = (node: any, event: React.MouseEvent) => {
    const auth = getAuth();

    event.stopPropagation();
    fetchWithTokenCheck(
      {
        method: "POST",
        url: `${AppConfig.apiUrl}/categories`,
        data: {
          Name: "New Category",
          Reference: "",
          Xcoord: "",
          Ycoord: "",
          SortOrder: 0,
          Parent: Number(node.id.split("-")[0]),
          Region:
            parentRegionMapping[
              node.parent.id as keyof typeof parentRegionMapping
            ],
        },
      },
      auth
    ).then(() => fetchCategories());
  };

  const handleEditNode = (
    nodeId: string,
    newName: string,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    setTreeData((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === nodeId) {
          return {
            ...item,
            name: newName,
          };
        }
        if (item.children) {
          return {
            ...item,
            children: updateNodeName(item.children, nodeId, newName),
          };
        }
        return item;
      });
      return newData;
    });
  };

  const updateNodeName = (
    nodes: SimpleTreeData[],
    nodeId: string,
    newName: string
  ): SimpleTreeData[] => {
    return nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          name: newName,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateNodeName(node.children, nodeId, newName),
        };
      }
      return node;
    });
  };

  const handleRemoveNode = (nodeId: string) => {
    const auth = getAuth();

    fetchWithTokenCheck(
      {
        method: "DELETE",
        url: `${AppConfig.apiUrl}/categories/${nodeId}`,
      },
      auth
    )
      .then(() => {
        toast.success("Deleted Category");
      })
      .catch(() => {
        toast.error("Failed to delete Category");
      });
    setTreeData((prevData) => {
      const newData = prevData
        .filter((node) => node.id !== nodeId)
        .map((item) => {
          if (item.children) {
            return {
              ...item,
              children: removeNode(item.children, nodeId),
            };
          }
          return item;
        });
      return newData;
    });
  };

  const removeNode = (
    nodes: SimpleTreeData[],
    nodeId: string
  ): SimpleTreeData[] => {
    return nodes
      .filter((node) => node.id !== nodeId)
      .map((node) => {
        if (node.children) {
          return {
            ...node,
            children: removeNode(node.children, nodeId),
          };
        }
        return node;
      });
  };

  const onMove: MoveHandler<SimpleTreeData> = ({
    dragIds,
    parentId,
    index,
  }) => {
    setTreeData((prevData) => {
      const newData = [...prevData];

      // Utility function to find a node by its ID
      const findNodeById = (
        data: SimpleTreeData[],
        id: string
      ): SimpleTreeData | null => {
        for (const item of data) {
          if (item.id === id) return item;
          if (item.children) {
            const childResult = findNodeById(item.children, id);
            if (childResult) return childResult;
          }
        }
        return null;
      };

      // Find the nodes to move
      const nodesToMove: SimpleTreeData[] = dragIds.map((id) => {
        const node = findNodeById(newData, id);
        if (node === null) {
          throw new Error(`Node with id ${id} not found`);
        }
        return node;
      });

      // Remove the nodes from their current location
      const removeNodesRecursively = (
        data: SimpleTreeData[],
        nodeIds: string[]
      ) => {
        return data.reduce((result, item) => {
          if (!nodeIds.includes(item.id)) {
            if (item.children) {
              item.children = removeNodesRecursively(item.children, nodeIds);
            }
            result.push(item);
          }
          return result;
        }, [] as SimpleTreeData[]);
      };
      const updatedData = removeNodesRecursively(newData, dragIds);

      const parentNode = parentId ? findNodeById(updatedData, parentId) : null;

      // Insert the nodes into their new location
      if (parentNode) {
        parentNode.children = parentNode.children || [];
        parentNode.children.splice(index, 0, ...nodesToMove);
      } else {
        updatedData.splice(index, 0, ...nodesToMove);
      }

      return updatedData;
    });
  };

  const handleSaveCategories = async () => {
    const auth = getAuth();
    const updatedCategories = reverseTransformData(treeData);

    const combinedCategories = [
      ...updatedCategories.gbPostalHistory,
      ...updatedCategories.gbStamps,
      ...updatedCategories.worldPostal,
    ];

    fetchWithTokenCheck(
      {
        method: "PUT",
        url: `${AppConfig.apiUrl}/categories`,
        data: {
          combinedCategories,
        },
      },
      auth
    );
  };

  function Node({ node, style, dragHandle }: NodeRendererProps<any>) {
    const hasChildren = node.data.children && node.data.children.length > 0;
    const isTopLevel = !node.parent;

    return (
      <div
        className={styles.nodes}
        style={style}
        ref={dragHandle}
        onClick={() => node.toggle()}
      >
        {node.isClosed ? "+" : "-"} {node.data.name}
        {!hasChildren && (
          <>
            <IconButton
              color="info"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                const newName = prompt("Enter new name", node.data.name);
                if (newName !== null && newName.trim() !== "") {
                  handleEditNode(node.id, newName, event);
                }
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                setRemoveNodeOpen(true);
                setNodeToRemove(node);
              }}
            >
              <Delete />
            </IconButton>
          </>
        )}
        {hasChildren &&
          !isTopLevel &&
          !parentRegionMapping[
            node.data.name as keyof typeof parentRegionMapping
          ] && (
            <IconButton
              color="success"
              size="small"
              onClick={(event) => handleAddNode(node, event)}
            >
              <Add />
            </IconButton>
          )}
      </div>
    );
  }

  const handleInputChange = (event: any) => {
    setConfirmationText(event.target.value);
  };

  return (
    <div>
      <Tree
        ref={treeApi}
        width={700}
        height={800}
        data={treeData}
        onMove={onMove}
        className={styles.treeContainer}
      >
        {Node}
      </Tree>
      <div className={styles.actions}>
        <Button variant="contained" onClick={() => handleSaveCategories()}>
          Save Categories & Order
        </Button>
      </div>
      <Dialog
        open={removeNodeOpen}
        onClose={() => {
          setRemoveNodeOpen(false);
          setNodeToRemove({});
          setConfirmationText("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category?
            <div style={{ color: "red" }}>
              {nodeToRemove.data ? nodeToRemove.data.name : ""}
            </div>
          </DialogContentText>
          <br />
          <DialogContentText>
            Type the category name to confirm deletion
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Category Name"
            type="text"
            fullWidth
            value={confirmationText}
            placeholder={nodeToRemove.data ? nodeToRemove.data.name : ""}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveNodeOpen(false);
              setNodeToRemove({});
              setConfirmationText("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemoveNode(nodeToRemove.id);
              setRemoveNodeOpen(false);
              setNodeToRemove({});
              setConfirmationText("");
            }}
            color="error"
            disabled={
              confirmationText !==
              (nodeToRemove.data ? nodeToRemove.data.name : "")
            }
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
