import {
  Button,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  Typography,
  ListItemText,
  Paper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styles from "./Orders.module.css";
import Loading from "../../../components/Loader/Loader";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import GenerateInvoice from "../../../components/GenerateInvoice/GenerateInvoice";
import { pdf } from "@react-pdf/renderer";
import PrintOrder from "../../../components/PrintOrder/PrintOrder";
import PrintLabel from "../../../components/PrintLabel/PrintLabel";
import { AppConfig } from "../../../utils/config";
import { cleanHtmlString } from "../../../utils/constants";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { fetchWithTokenCheck } from "../../../utils/utils";
import TransactionInfo from "../../../components/Admin/Orders/TransactionInfo";
import { Order } from "../../../types/admin/orders";

const parseHTMLString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return cleanHtmlString(doc.body.textContent || "");
};

export default function Orders() {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchResults, setSearchResults] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [orderViewOpen, setOrderViewOpen] = useState(false);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchOrderValue, setSearchOrderValue] = useState("by order number");

  const handleSearchOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOrderValue((event.target as HTMLInputElement).value);
  };

  function fetchOrders() {
    setLoading(true);
    const auth = getAuth();
    const searchVal = searchRef.current?.value.toLowerCase();
    const searchFilter =
      searchOrderValue === "by order number" ? "order" : "name";
    setSearchResults([]);

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/orders?page=${page + 1}${
          searchVal && `&${searchFilter}=${searchVal}`
        }`,
      },
      auth
    ).then((res) => {
      setOrders(res.data.orders);
      setTotalCount(res.data.totalCount);
      setLoading(false);
    });
  }

  const handleSearch = () => {
    const searchVal = searchRef.current?.value.toLowerCase();
    setSearchResults([]);
    if (searchVal) {
      const searchFilter =
        searchOrderValue === "by order number" ? "order" : "name";
      setLoading(true);
      const auth = getAuth();
      fetchWithTokenCheck(
        {
          method: "GET",
          url: `${AppConfig.apiUrl}/orders?${searchFilter}=${searchVal}`,
        },
        auth
      )
        .then((res) => {
          setSearchResults(res.data.orders);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.warn(err);
        });
    } else {
      fetchOrders();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const columns: GridColDef[] = [
    {
      field: "orderNo",
      headerName: "Order Number",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "Full Name",
      headerName: "Customer Name",
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) =>
        `${params.row.customer.title} ${params.row.customer.name} ${params.row.customer.lastName}`,
    },

    {
      field: "email",
      headerName: "E-mail address",
      width: 200,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.customer.email,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 200,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        return dayjs(params.row.orderDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const total = Number(params.row.totalCost) + Number(params.row.postage);
        return `£${Number(total).toFixed(2)}`;
      },
    },
  ];

  const handleRowClick = async (params: any) => {
    const selectedOrder =
      searchResults.length === 0
        ? orders.find((order: Order) => order.orderNo === params)
        : searchResults.find((a) => a.orderNo === params);
    setSelectedOrder(selectedOrder || null);
    //Update to new fetch once everything else is organised
  };

  const useOrderNoAsId = (row: Order) => {
    return row.orderNo;
  };

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const handleUpdateMenu = () => {
    setOrderViewOpen(!orderViewOpen);
    //setUpdateCustomerValues(initialCustomerState);
  };

  const generateInvoice = async () => {
    if (selectedOrder) {
      const blob = await pdf(<GenerateInvoice data={selectedOrder} />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const printOrder = async () => {
    if (selectedOrder) {
      const blob = await pdf(<PrintOrder data={selectedOrder} />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const printLabel = async () => {
    if (selectedOrder) {
      const blob = await pdf(<PrintLabel data={selectedOrder} />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  // TODO View orders by YEAR range (From To Default 8 years) Button
  // TODO Click here to enter free text order for a customer?
  // TODO List of orders

  return (
    <div className={styles.orderContainer}>
      <h2>View Order Summary</h2>
      <div>
        <h2>Search Orders</h2>
        <div>
          <OutlinedInput
            size="small"
            name="Search..."
            placeholder="Search..."
            inputRef={searchRef}
            onKeyDown={handleKeyDown}
          />
          <FormControl>
            <RadioGroup
              row
              value={searchOrderValue}
              onChange={handleSearchOrderChange}
              sx={{ px: 2 }}
            >
              <FormControlLabel
                value="by order number"
                control={<Radio />}
                label="By order number"
              />
              <FormControlLabel
                value="by customer name"
                control={<Radio />}
                label="By customer name"
              />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <h2>Orders</h2>
      </div>
      {loading ? (
        <div className={styles.inlineLoader}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.customerTable}>
            {" "}
            <DataGrid
              getRowId={useOrderNoAsId}
              rows={
                searchResults && searchResults.length > 0
                  ? [...searchResults].sort((a, b) => b.orderNo - a.orderNo)
                  : [...orders].sort((a, b) => b.orderNo - a.orderNo)
              }
              columns={columns}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              rowCount={totalCount}
              loading={loading}
              paginationModel={{
                page: page,
                pageSize: pageSize,
              }}
              onRowClick={(params) => {
                handleRowClick(params.id);
                handleUpdateMenu();
              }}
              onPaginationModelChange={handlePaginationModelChange}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
            />
          </div>
        </>
      )}

      {/* New Order Dialog */}
      <Dialog
        open={orderViewOpen}
        fullWidth
        maxWidth="xl"
        onClose={handleUpdateMenu}
      >
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6" component="div">
            Order Number: {selectedOrder?.orderNo}
          </Typography>
          <Typography variant="h6" component="div">
            Order Date: {selectedOrder?.orderDate}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography variant="h6" component="div">
                  Customer Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="fullName"
                        name="FullName"
                        size="small"
                        placeholder="Full Name"
                        value={`${selectedOrder?.customer.title} ${selectedOrder?.customer.name} ${selectedOrder?.customer.lastName}`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="email"
                        name="Email"
                        size="small"
                        placeholder="Email"
                        value={selectedOrder?.customer.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="address1"
                        name="Address1"
                        size="small"
                        placeholder="Address 1"
                        value={selectedOrder?.customer.address1}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="address2"
                        name="Address2"
                        size="small"
                        placeholder="Address 2"
                        value={selectedOrder?.customer.address2}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="town"
                        name="Town"
                        size="small"
                        placeholder="Town"
                        value={selectedOrder?.customer.town}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="county"
                        name="County"
                        size="small"
                        placeholder="County"
                        value={selectedOrder?.customer.county}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="postcode"
                        name="Postcode"
                        size="small"
                        placeholder="Postcode"
                        value={selectedOrder?.customer.postcode}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="country"
                        name="Country"
                        size="small"
                        placeholder="Country"
                        value={selectedOrder?.customer.country}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* SECOND GRID*/}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography variant="h6" component="div">
                  Shipping Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="fullName"
                        name="FullName"
                        size="small"
                        placeholder="Full Name"
                        value={`${selectedOrder?.customer.title} ${selectedOrder?.customer.name} ${selectedOrder?.customer.lastName}`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="address1"
                        name="Address1"
                        size="small"
                        placeholder="Address 1"
                        value={selectedOrder?.address1}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="address2"
                        name="Address2"
                        size="small"
                        placeholder="Address 2"
                        value={selectedOrder?.address2}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="town"
                        name="Town"
                        size="small"
                        placeholder="Town"
                        value={selectedOrder?.town}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="county"
                        name="County"
                        size="small"
                        placeholder="County"
                        value={selectedOrder?.county}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="postcode"
                        name="Postcode"
                        size="small"
                        placeholder="Postcode"
                        value={selectedOrder?.postcode}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        id="country"
                        name="Country"
                        size="small"
                        placeholder="Country"
                        value={selectedOrder?.country}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Items
                </Typography>
                <List>
                  {selectedOrder?.items.map((item) => (
                    <ListItem key={item.id}>
                      <ListItemText
                        primary={`£${item.price} - ${parseHTMLString(
                          item.title
                        )}`}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body1"
                              color="textSecondary"
                            >
                              Description: {parseHTMLString(item.description)}
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              variant="body2"
                              color="textSecondary"
                            >
                              Reference: {item.reference}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="body1" component="span">
                  Total Shipping: £{Number(selectedOrder?.postage).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {selectedOrder?.buyNotes && (
            <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Customer Notes
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "8px" }}>
                    {selectedOrder?.buyNotes}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
            {selectedOrder && <TransactionInfo order={selectedOrder} />}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={2}>
                <Button
                  onClick={printOrder}
                  color="primary"
                  variant="contained"
                  style={{ minWidth: "2rem" }}
                >
                  Print Order
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Button
                  onClick={generateInvoice}
                  color="primary"
                  variant="contained"
                  style={{ minWidth: "2rem" }}
                >
                  Generate Invoice
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Button
                  onClick={printLabel}
                  color="primary"
                  variant="contained"
                  style={{ minWidth: "2rem" }}
                >
                  Generate Label
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Button
                  onClick={handleUpdateMenu}
                  color="primary"
                  variant="contained"
                  style={{ minWidth: "2rem" }}
                >
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </div>
  );
}
