import styles from "./Cart.module.css";
import useAppState from "../../hooks/useAppState";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import type { Product } from "../Home/Home";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeError } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { AppConfig } from "../../utils/config";
import { cleanHtmlString } from "../../utils/constants";

const stripePromise = loadStripe(AppConfig.stripeKeyProd);

export default function Cart() {
  const { shoppingCart, setShoppingCart } = useAppState();
  const [error, setError] = useState<StripeError | null>(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [shippingCost, setShippingCost] = useState<number>(0);

  const totalCost = useCallback(() => {
    // @ts-ignore
    return shoppingCart.reduce((acc, val) => acc + val.Price, 0) + shippingCost;
  }, [shoppingCart, shippingCost]);

  useEffect(() => {
    if (stripeClientSecret) {
      // Update existing payment intent
      axios
        .post(`${AppConfig.apiUrl}/update-payment-intent`, {
          price: parseInt((totalCost() * 100).toFixed(0)),
          paymentIntentId: paymentIntentId,
        })
        .then((res) => {
          setStripeClientSecret(res.data.clientSecret);
        });
    } else {
      // Create new payment intent
      axios
        .post(`${AppConfig.apiUrl}/create-payment-intent`, {
          price: parseInt((totalCost() * 100).toFixed(0)),
        })
        .then((res) => {
          setStripeClientSecret(res.data.clientSecret);
          setPaymentIntentId(res.data.paymentIntentId);
        });
    }
  }, [totalCost, stripeClientSecret, paymentIntentId]);

  function removeFromBasket(data: Product) {
    const newCart = shoppingCart.filter((a) => a.ID !== data.ID);
    setShoppingCart(newCart);
  }

  return (
    <div className={styles.itemContainer}>
      {shoppingCart.length === 0 ? (
        <div className={styles.emptyCart}>
          <h2>Your cart is empty</h2>
          <p>Looking for something special?</p>
          <Link to="/">
            <Button variant="contained" disableElevation>
              Browse Items
            </Button>
          </Link>
        </div>
      ) : null}
      <div className={styles.items}>
        {shoppingCart.map((item) => {
          return (
            <div key={item.ID} className={styles.item}>
              <div className={styles.itemDescription}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/item/${item.ID}`}
                  tabIndex={-1}
                >
                  <img
                    src={`${AppConfig.smImgUrl}/${item.ID}.jpg`}
                    alt={`${item.ID} item`}
                  />
                </Link>
                <br />
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontFamily: "Book Antiqua",
                  }}
                  to={`/item/${item.ID}`}
                  tabIndex={-1}
                  dangerouslySetInnerHTML={{
                    __html: cleanHtmlString(item.Title).replace(
                      /\\r\\n/g,
                      "\n"
                    ),
                  }}
                />
              </div>
              <div className={styles.itemBuyZone}>
                <div>£{item.Price}</div>
                <Button
                  onClick={() => removeFromBasket(item)}
                  size="small"
                  variant="contained"
                  disableElevation
                >
                  Remove
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      {shoppingCart.length > 0 && stripeClientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: stripeClientSecret,
          }}
        >
          <CheckoutForm
            setShippingCost={setShippingCost}
            order={shoppingCart}
            totalCost={totalCost}
            setError={setError}
            error={error}
          />
        </Elements>
      )}
    </div>
  );
}
