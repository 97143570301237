import Header from "../../components/Header/Header";
import styles from "./App.module.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Admin from "../Admin/Admin";
import Home from "../Home/Home";
import Item from "../Item/Item";
import Footer from "../../components/Footer/Footer";
import Cart from "../Cart/Cart";
import PrivateRoutes from "../../components/PrivateRoutes/PrivateRoutes";
import Stock from "../Admin/Stock/Stock";
import Orders from "../Admin/Orders/Orders";
import Customers from "../Admin/Customers/Customers";
import Category from "../Admin/Category/Category";
import Postage from "../Admin/Postage/Postage";
import Discounts from "../Admin/Discounts/Discounts";
import Holiday from "../Admin/Holiday/Holiday";
import Static from "../Admin/Static/Static";
import Accounts from "../Admin/Accounts/Accounts";
import Settings from "../Admin/Settings/Settings";
import AboutUs from "../Static/AboutUs";
import Payment from "../Static/Payment";
import Delivery from "../Static/Delivery";
import Guarantee from "../Static/Guarantee";
import Valuation from "../Static/Valuation";
import Terms from "../Static/Terms";
import Contact from "../Static/Contact";
import OrderSuccess from "../OrderSuccess/OrderSuccess";
import CustomerCategory from "../CustomerCategory/CustomerCategory";

export default function App() {
  const location = useLocation();
  const onAdmin = location.pathname.includes("/admin");
  return (
    <div className={styles.app}>
      {!onAdmin ? <Header /> : null}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:id" element={<CustomerCategory />} />
        <Route path="admin" element={<Admin />} />
        <Route path="/item/:id" element={<Item />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/guarantee" element={<Guarantee />} />
        <Route path="/valuation" element={<Valuation />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/success" element={<OrderSuccess />} />
        <Route element={<PrivateRoutes />}>
          <Route
            path="admin/stock"
            element={
              <Admin>
                <Stock />
              </Admin>
            }
          />
          <Route
            path="admin/orders"
            element={
              <Admin>
                <Orders />
              </Admin>
            }
          />
          <Route
            path="admin/customers"
            element={
              <Admin>
                <Customers />
              </Admin>
            }
          />
          <Route
            path="admin/category"
            element={
              <Admin>
                <Category />
              </Admin>
            }
          />
          <Route
            path="admin/postage"
            element={
              <Admin>
                <Postage />
              </Admin>
            }
          />
          <Route
            path="admin/discounts"
            element={
              <Admin>
                <Discounts />
              </Admin>
            }
          />
          <Route
            path="admin/holiday"
            element={
              <Admin>
                <Holiday />
              </Admin>
            }
          />
          <Route
            path="admin/static"
            element={
              <Admin>
                <Static />
              </Admin>
            }
          />
          <Route
            path="admin/accounts"
            element={
              <Admin>
                <Accounts />
              </Admin>
            }
          />
          <Route
            path="admin/settings"
            element={
              <Admin>
                <Settings />
              </Admin>
            }
          />
        </Route>
      </Routes>
      {!onAdmin ? <Footer /> : null}
    </div>
  );
}
