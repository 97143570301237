import { useEffect, useState } from "react";
import styles from "./Version.module.css";

export default function Version() {
  const [version, setVersion] = useState<string>("");

  useEffect(() => {
    fetch("/version.json")
      .then((res) => res.json())
      .then((data) => setVersion(data.version))
      .catch((error) => console.warn("Error fetching version:", error));
  }, []);

  return (
    <div className={styles.versionContainer}>
      <p>Version: {version}</p>
    </div>
  );
}
