import styles from './Loader.module.css'

export default function Loading({ fullscreen = false }) {
  if (!fullscreen)
    return (
      <div className={styles.loader}>
        <div className={styles.inner} />
      </div>
    )

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayDoor} />
      <div className={styles.overlayContent}>
        <div className={styles.loader}>
          <div className={styles.inner} />
        </div>
      </div>
    </div>
  )
}
