export const staticPagesMap = {
  About: 1,
  Payment: 2,
  Delivery: 3,
  Guarantee: 4,
  Valuation: 5,
  Terms: 6,
  Contact: 7,
  Banner: 8,
}
