import { Document, StyleSheet, Page, View, Text } from "@react-pdf/renderer";
import { Order } from "../../types/admin/orders";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  customerDetails: {
    fontSize: 15,
  },
});

export default function PrintLabel({ data }: { data: Order }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.customerDetails}>
            {data.customer.title} {data.customer.name} {data.customer.lastName}
          </Text>
          <Text style={styles.customerDetails}>{data.address1}</Text>
          <Text style={styles.customerDetails}>{data.customer.address2}</Text>
          <Text style={styles.customerDetails}>
            {data.customer.town}, {data.customer.county}
          </Text>
          <Text style={styles.customerDetails}>{data.customer.postcode}</Text>
          <Text style={styles.customerDetails}>{data.customer.country}</Text>
        </View>
      </Page>
    </Document>
  );
}
