import styles from "../../pages/Home/Home.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, TextField, Pagination } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useAppState from "../../hooks/useAppState";
import axios from "axios";
import Loading from "../../components/Loader/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { AppConfig } from "../../utils/config";
import { cleanHtmlString } from "../../utils/constants";
import { Product, scrollToTop } from "../../pages/Home/Home";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { Helmet } from "react-helmet";

type ItemsProps = {
  isHome: boolean;
  category?: number;
  search?: string;
};

export default function Items({ isHome, category, search }: ItemsProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hidePaginator, setHidePaginator] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    shoppingCart,
    setShoppingCart,
    selectedPage,
    setSelectedPage,
    selectedCategoryName,
  } = useAppState();
  const { saveScrollPosition, restoreScrollPosition, clearScrollPosition } =
    useScrollPosition("items-scroll-position");

  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();

  const getProducts = useCallback(async () => {
    const searchParams = new URLSearchParams(locationSearch);
    const urlSearch = searchParams.get("search") || "";

    const productUrl =
      isHome && !urlSearch
        ? `${AppConfig.apiUrl}/products`
        : `${AppConfig.apiUrl}/products?page=${selectedPage}${
            urlSearch ? `&search=${urlSearch}` : ""
          }${category ? `&category=${category}` : ""}`;

    setLoading(true);
    axios
      .get(productUrl)
      .then((res) => {
        setProducts(res.data.Products);
        setTotalCount(res.data.TotalCount);
        setHidePaginator(res.data.HidePaginator);
        setTimeout(() => {
          restoreScrollPosition();
        }, 250);
      })
      .catch((err) => console.warn(err))
      .finally(() => setLoading(false));
  }, [category, isHome, locationSearch, selectedPage, restoreScrollPosition]);

  useEffect(() => {
    getProducts();
  }, [category, getProducts]);

  function addToBasket(data: Product) {
    setShoppingCart([...shoppingCart, data]);
  }

  function removeFromBasket(data: Product) {
    const newCart = shoppingCart.filter((a) => a.ID !== data.ID);
    setShoppingCart(newCart);
  }

  function itemInCart(id: number) {
    return shoppingCart.some((a) => a.ID === id) || false;
  }

  const handleSearch = () => {
    clearScrollPosition();
    const searchParams = new URLSearchParams();
    searchParams.set("search", searchTerm);
    // If on home page, navigate to /products with search params
    const targetPath = window.location.pathname;
    navigate(`${targetPath}?${searchParams.toString()}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    clearScrollPosition();
    setSelectedPage(value);
    scrollToTop();
  };

  if (loading)
    return (
      <div className={styles.inlineLoader}>
        <Loading />
      </div>
    );

  return (
    <div>
      <Helmet>
        Martin Townsend {selectedCategoryName && `| ${selectedCategoryName}`}
      </Helmet>
      <div className={styles.searchBar}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          className={styles.searchButton}
          startIcon={<SearchIcon />}
          disableElevation
        >
          Search
        </Button>
      </div>
      {!hidePaginator && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "1rem",
          }}
        >
          <Pagination
            count={Math.ceil(totalCount / 60)}
            variant="outlined"
            shape="rounded"
            page={selectedPage}
            onChange={handlePageChange}
          />
        </div>
      )}

      {products && products.length > 0 ? (
        <div className={styles.itemContainer} onClick={saveScrollPosition}>
          {products.map((product) => {
            return (
              <div key={product.ID} className={styles.item}>
                <div className={styles.itemDescription}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/item/${product.ID}`}
                    tabIndex={-1}
                  >
                    <div
                      style={{
                        height: "160px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`${AppConfig.smImgUrl}/${product.ID}.jpg`}
                        alt={`${product.ID} item`}
                      />
                    </div>
                  </Link>
                  <br />
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      fontFamily: "Book Antiqua",
                    }}
                    to={`/item/${product.ID}`}
                    tabIndex={-1}
                    dangerouslySetInnerHTML={{
                      __html: cleanHtmlString(product.Title),
                    }}
                  />
                </div>
                <div className={styles.itemBuyZone}>
                  <div>£{product.Price}</div>
                  {!itemInCart(product.ID) ? (
                    <Button
                      onClick={() =>
                        addToBasket({
                          ...product,
                        })
                      }
                      size="small"
                      variant="contained"
                      disableElevation
                    >
                      Add to basket
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        gap: "0.5rem",
                      }}
                    >
                      <Button
                        style={{ width: "50%" }}
                        onClick={() =>
                          removeFromBasket({
                            ...product,
                          })
                        }
                        size="small"
                        variant="contained"
                        disableElevation
                      >
                        Remove
                      </Button>
                      <Button
                        style={{ width: "50%" }}
                        size="small"
                        variant="contained"
                        disableElevation
                        onClick={() => navigate("/cart")}
                      >
                        Basket
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.noProductsFound}>
          <p>No products found. Please adjust your search criteria.</p>
        </div>
      )}
      {!hidePaginator && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "1rem",
          }}
        >
          <Pagination
            count={Math.ceil(totalCount / 60)}
            variant="outlined"
            shape="rounded"
            page={selectedPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}
