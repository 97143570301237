import { useParams, useNavigate } from "react-router-dom";
import type { Product } from "../Home/Home";
import { Button } from "@mui/material";
import styles from "./Item.module.css";
import useAppState from "../../hooks/useAppState";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Loading from "../../components/Loader/Loader";
import { AppConfig } from "../../utils/config";
import { cleanHtmlString } from "../../utils/constants";
import { Helmet } from "react-helmet";
import "react-image-gallery/styles/css/image-gallery.css";
import { GalleryImage } from "../../types/images";
import ImageGallery from "react-image-gallery";
import Gallery from "../../components/Gallery/Gallery";
export default function Item() {
  const { id } = useParams();
  const { shoppingCart, setShoppingCart } = useAppState();
  const [product, setProduct] = useState<Product>();
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<GalleryImage[]>([]);
  const galleryRef = useRef<ImageGallery>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const imgMap = product?.Images
      ? product.Images.sort(
          (a, b) => +a.Reference.includes("-") - +b.Reference.includes("-")
        ).map((img) => ({
          original: `${AppConfig.lgImgUrl}/${img.Reference}`,
          thumbnail: `${AppConfig.smImgUrl}/${img.Reference}`,
        }))
      : [
          {
            original: `${AppConfig.lgImgUrl}/${id}.jpg`,
            thumbnail: `${AppConfig.smImgUrl}/${id}.jpg`,
          },
        ];

    setImages(imgMap);
  }, [product, id]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${AppConfig.apiUrl}/products/${id}`)
      .then((res) => setProduct(res.data))
      .catch((err) => console.warn(err))
      .finally(() => setLoading(false));
  }, [id]);

  function addToBasket(data: Product) {
    setShoppingCart([
      ...shoppingCart,
      {
        ...data,
        ID: Number(id),
      },
    ]);
  }

  function removeFromBasket(data: Product) {
    const newCart = shoppingCart.filter((a) => a.ID !== Number(id));
    setShoppingCart(newCart);
  }

  function itemInCart(id: number) {
    return shoppingCart.some((a) => a.ID === id) || false;
  }

  if (!id) {
    return <div>No Item Selected</div>;
  }

  if (loading) {
    return (
      <div className={styles.inlineLoader}>
        <Loading />
      </div>
    );
  }

  if (!product) {
    return <div>No Item Found</div>;
  }

  const isOutOfStock = Number(product.Quantity) === 0;

  return (
    <>
      <Helmet>
        <title>{cleanHtmlString(product.CleanTitle.String)}</title>
      </Helmet>
      <div className={styles.itemDetailedContainer}>
        <div className={styles.galleryContainer}>
          <Gallery images={images} galleryRef={galleryRef} />
          <Button
            variant="contained"
            onClick={() => galleryRef.current?.fullScreen()}
          >
            View Fullscreen
          </Button>
        </div>
        <div className={styles.itemDetails}>
          <div
            className={styles.itemTitle}
            dangerouslySetInnerHTML={{
              __html: `${cleanHtmlString(product.Title)} ${cleanHtmlString(
                product.Description
              )}`,
            }}
          />
          <div className={styles.itemReference}>
            Item Reference: {product.Reference}
          </div>
          {isOutOfStock && (
            <div className={styles.outOfStock}>
              Sorry, this item is out of stock
            </div>
          )}
          {!itemInCart(Number(id)) ? (
            <Button
              variant="contained"
              onClick={() => addToBasket(product)}
              disabled={isOutOfStock}
            >
              £{product.Price} - Add to basket
            </Button>
          ) : (
            <div className={styles.itemButtonContainer}>
              <Button
                variant="contained"
                onClick={() => removeFromBasket(product)}
              >
                £{product.Price} - Remove
              </Button>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={() => navigate("/cart")}
              >
                View Basket
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
